angular.module('app.templateModule', []).
run(['$templateCache', function($templateCache) {
  $templateCache.put('auth/directives/change-password-modal.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<div class="login-content col-md-12">\n' +
    '    <h3 class="primary">Change Password</h3>\n' +
    '\n' +
    '    <div class="alert alert-warning" ng-show="modalCtrl.notification.text">\n' +
    '        {[{ modalCtrl.notification.text }]}\n' +
    '    </div>\n' +
    '    <form class="login-form" ng-submit="modalCtrl.submit()">\n' +
    '\n' +
    '        <fieldset class="input-fields">\n' +
    '            <div class="form-group">\n' +
    '                <input type="password" ng-model="modalCtrl.form.old_password" class="form-control" placeholder="Current Password">\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '                <input type="password" ng-model="modalCtrl.form.new_password" class="form-control" placeholder="New Password">\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '                <input type="password" ng-model="modalCtrl.other.new_password" class="form-control" placeholder="Confirm New Password">\n' +
    '            </div>\n' +
    '\n' +
    '        </fieldset>\n' +
    '        <div class="form-group">\n' +
    '            <input type="submit" class="btn btn-primary" value="Submit">\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</div>\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('auth/directives/login.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<div class="login-content col-md-12">\n' +
    '    <h3 class="primary">Log in</h3>\n' +
    '\n' +
    '    <div class="alert alert-warning" ng-show="modalCtrl.notification.category != \'login_password\' && modalCtrl.notification.text">\n' +
    '        {[{ modalCtrl.notification.text }]}\n' +
    '    </div>\n' +
    '    <form class="login-form" ng-submit="modalCtrl.submitLoginForm()">\n' +
    '\n' +
    '        <fieldset class="input-fields">\n' +
    '            <div class="form-group"> <!--ng-hide="modalCtrl.notification.category == \'login_password\'"-->\n' +
    '                <input type="text" name="email" id="loginUsername" class="form-control" ng-model="modalCtrl.loginForm.email" placeholder="Email">\n' +
    '            </div>\n' +
    '            <div class="form-group"> <!--ng-if="modalCtrl.notification.category == \'login_password\'"-->\n' +
    '                <input type="password" name="password" id="loginPassword" class="form-control" ng-model="modalCtrl.loginForm.login_password" placeholder="Password">\n' +
    '            </div>\n' +
    '            <div>\n' +
    '                <a href ng-click="modalCtrl.forgot()" class="small pull-right primary">Forgot your password?</a>\n' +
    '            </div>\n' +
    '\n' +
    '        </fieldset>\n' +
    '        <div class="form-group">\n' +
    '            <input type="submit" class="btn btn-primary" value="{[{ modalCtrl.notification.category == \'login_password\' ? \'Next\' : \'Login\' }]}">\n' +
    '        </div>\n' +
    '    </form>\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '<!--<div class="signup-content col-md-6">\n' +
    '    <h3>New Partner</h3>\n' +
    '    <p>\n' +
    '        <strong>Register</strong>\n' +
    '    </p>\n' +
    '    <p>\n' +
    '        This content is viewable by registered partners only. Click the button below to create a new account. Or click here to learn more about the benefits of creating a partner account.\n' +
    '    </p>\n' +
    '    <p>\n' +
    '        <a ng-click="modalCtrl.register(\'local\')" class="btn btn-primary">Register</a>\n' +
    '    </p>\n' +
    '</div>-->\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('auth/directives/register.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<form class="register-content" ng-submit="modalCtrl.submit()">\n' +
    '    <h3 class="primary">New Account</h3>\n' +
    '    <p>\n' +
    '        <strong>Register</strong>\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="alert alert-warning" ng-show="modalCtrl.notification.text" ng-bind-html="modalCtrl.notification.text | nl2br">\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-sm-6">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.first_name}">\n' +
    '                <input type="text" ng-model="modalCtrl.form.first_name" class="form-control" placeholder="First Name" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.last_name}">\n' +
    '                <input type="text" ng-model="modalCtrl.form.last_name" class="form-control" placeholder="Last Name" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.email}">\n' +
    '                <input type="email" ng-model="modalCtrl.form.email" class="form-control" placeholder="Email" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.company}">\n' +
    '                <input type="text" ng-model="modalCtrl.form.company" class="form-control" placeholder="Company" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-sm-6" ng-if="modalCtrl.registerAccountType >= 20">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.position_title}">\n' +
    '                <input type="text" ng-model="modalCtrl.form.position_title" class="form-control" placeholder="Position Title" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6" ng-if="modalCtrl.registerAccountType >= 20">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.password}">\n' +
    '                <input type="password" ng-model="modalCtrl.form.password" class="form-control" placeholder="Password" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6" ng-if="modalCtrl.registerAccountType >= 20">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.password_confirmation}">\n' +
    '                <input type="password" ng-model="modalCtrl.form.password_confirmation" class="form-control" placeholder="Confirm Password" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6" ng-if="modalCtrl.registerAccountType >= 20">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.address}">\n' +
    '                <input type="text" ng-model="modalCtrl.form.address" class="form-control" placeholder="Address" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6" ng-if="modalCtrl.registerAccountType >= 20">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.suburb}">\n' +
    '                <input type="text" ng-model="modalCtrl.form.suburb" class="form-control" placeholder="Suburb" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6" ng-if="modalCtrl.registerAccountType >= 20">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.state}">\n' +
    '                <input type="text" ng-model="modalCtrl.form.state" class="form-control" placeholder="State" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6" ng-if="modalCtrl.registerAccountType >= 20">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.postcode}">\n' +
    '                <input type="text" ng-model="modalCtrl.form.postcode" class="form-control" placeholder="Postcode" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-sm-6 col-checkbox">\n' +
    '            <input type="checkbox" class="theme-checkbox" required id="terms">\n' +
    '            <label for="terms">\n' +
    '                I accept the terms and conditions\n' +
    '            </label>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '    <input type="submit" class="btn btn-primary" value="Register">\n' +
    '</form>'
  );
  $templateCache.put('common/directives/disk-row-directory.html',
    '<td jump-to-directory dir-id="item.id" dir-name="item.name" class="thumb">\n' +
    '    <img class="thumb" src="/img/file-extensions/folder.png">\n' +
    '</td>\n' +
    '<td jump-to-directory dir-id="item.id" dir-name="item.name">\n' +
    '    {[{ item.name }]}\n' +
    '</td>\n' +
    '<td>\n' +
    '    {[{ item.created_at | amUtc | amDateFormat:"DD/MM/YYYY h:mma" }]}\n' +
    '    <br>\n' +
    '    <span class="text-muted">by {[{ item.user.name }]}</span>\n' +
    '</td>\n' +
    '<td class="tags-td">\n' +
    '    <ul class="links">\n' +
    '        <li ng-repeat="tag in item.tags | limitTo:7">\n' +
    '            <span class="label label-default" ng-class="{\'label-red\': tag.level == 0}">\n' +
    '                {[{ tag.name }]}\n' +
    '            </span>\n' +
    '        </li>\n' +
    '        <li ng-if="item.tags.length > 7">\n' +
    '            <span class="label label-default">\n' +
    '                ...\n' +
    '            </span>\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '</td>\n' +
    '<td>\n' +
    '    <ul class="links pull-right">\n' +
    '        <li class="show-on-hover" ng-if="item.trueWritable">\n' +
    '            <a href class="btn btn-macaroni btn-sm" object-type="directory" share-object="item">\n' +
    '                <i class="icon icon_share hidden-sm">&nbsp;</i>\n' +
    '                Share\n' +
    '            </a>\n' +
    '        </li>\n' +
    '        <li class="show-on-hover" ng-if="item.trueWritable">\n' +
    '            <a href class="btn btn-macaroni btn-sm" object-type="directory" tag-object="item">\n' +
    '                <i class="icon icon_tags2 hidden-sm">&nbsp;</i>\n' +
    '                Tags\n' +
    '            </a>\n' +
    '        </li>\n' +
    '        <li ng-if="!item.trueWritable">\n' +
    '            <a href download-directory class="btn btn-macaroni btn-sm" directory-id="item.id">\n' +
    '                <i class="icon icon_download-2 hidden-sm">&nbsp;</i>\n' +
    '                Download\n' +
    '            </a>\n' +
    '        </li>\n' +
    '        <li class="btn-group" uib-dropdown ng-if="item.trueWritable">\n' +
    '            <a id="single-button" type="button" class="btn btn-macaroni btn-sm" uib-dropdown-toggle ng-disabled="disabled">\n' +
    '                <!--More <span class="caret"></span>-->\n' +
    '                <i class="icon icon_more">&nbsp;</i>\n' +
    '            </a>\n' +
    '            <ul class="dropdown-menu dropdown-menu-right" uib-dropdown-menu role="menu" aria-labelledby="single-button">\n' +
    '                <li role="menuitem">\n' +
    '                    <a href download-directory directory-id="item.id">\n' +
    '                        <i class="icon icon_download-2 hidden-sm">&nbsp;</i>\n' +
    '                        Download\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '                <!-- <li role="menuitem" class="hidden" show-if-has-permission="{[{ list.id ? \'\' : \'root-directory.writable\' }]}">-->\n' +
    '                <li role="menuitem">\n' +
    '                    <a href rename-directory on-object="item">\n' +
    '                        Rename\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '                <li role="menuitem" class="hidden" show-if-is-admin show-if-is-owner="item.user_id">\n' +
    '                    <a href permission-directory on-object="item">\n' +
    '                        Edit Permissions\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '                <li role="menuitem" class="hidden" show-if-can-move="item.can_move">\n' +
    '                    <a href move-directory on-object="item" type="directory" move-id="item.can_move">\n' +
    '                        Move Directory\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '                <li class="divider"></li>\n' +
    '                <!--  <li role="menuitem" class="hidden" show-if-has-permission="{[{ list.id ? \'\' : \'root-directory.writable\' }]}">-->\n' +
    '                <li role="menuitem">\n' +
    '                    <a href delete-directory on-object="item">\n' +
    '                        <span class="text-danger">\n' +
    '                            Delete\n' +
    '                        </span>\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '</td>'
  );
  $templateCache.put('common/directives/disk-row-file.html',
    '<td class="thumb">\n' +
    '    <input type="checkbox" ng-model="item.isSelected" ng-change="onFileSelection()">\n' +
    '    <!--download-file file-info-id="item.id" trigger-action="dblClick"-->\n' +
    '    <span preview-file on-object="item">\n' +
    '        <img class="thumb" ng-src="/thumbnail/file/sm/{[{ item.id }]}">\n' +
    '    </span>\n' +
    '</td>\n' +
    '<td preview-file on-object="item">\n' +
    '    {[{ item.name }]}\n' +
    '    <br>\n' +
    '    <small class="text-muted">{[{ item.cloud_file.file_size | filesize }]}</small>\n' +
    '</td>\n' +
    '<td>\n' +
    '    {[{ item.created_at | amUtc | amDateFormat:"DD/MM/YYYY h:mma" }]}\n' +
    '    <br>\n' +
    '    <span class="text-muted">by {[{ item.user.name }]}</span>\n' +
    '</td>\n' +
    '<td class="tags-td">\n' +
    '    <ul class="links">\n' +
    '        <li ng-repeat="tag in item.tags | limitTo:7">\n' +
    '            <span class="label label-default" ng-class="{\'label-red\': tag.level == 0}">\n' +
    '                {[{ tag.name }]}\n' +
    '            </span>\n' +
    '        </li>\n' +
    '        <li ng-if="item.tags.length > 7">\n' +
    '            <span class="label label-default"> ... </span>\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '</td>\n' +
    '<td>\n' +
    '    <ul class="links pull-right">\n' +
    '        <li class="show-on-hover" ng-if="item.trueWritable">\n' +
    '            <a href class="btn btn-macaroni btn-sm" object-type="file" share-object="item">\n' +
    '                <i class="icon icon_share hidden-sm">&nbsp;</i>\n' +
    '                Share\n' +
    '            </a>\n' +
    '        </li>\n' +
    '        <li class="show-on-hover" ng-if="item.trueWritable">\n' +
    '            <a href class="btn btn-macaroni btn-sm" object-type="file" tag-object="item">\n' +
    '                <i class="icon icon_tags2 hidden-sm">&nbsp;</i>\n' +
    '                Tags\n' +
    '            </a>\n' +
    '        </li>\n' +
    '        <li ng-if="!item.trueWritable">\n' +
    '            <a href download-file class="btn btn-macaroni btn-sm" file-info-id="item.id">\n' +
    '                <i class="icon icon_download-2 hidden-sm">&nbsp;</i>\n' +
    '                Download\n' +
    '            </a>\n' +
    '        </li>\n' +
    '        <li class="btn-group" uib-dropdown ng-if="item.trueWritable">\n' +
    '            <a id="single-button" type="button" class="btn btn-macaroni btn-sm" uib-dropdown-toggle>\n' +
    '                <!--More <span class="caret"></span>-->\n' +
    '                <i class="icon icon_more">&nbsp;</i>\n' +
    '            </a>\n' +
    '            <ul class="dropdown-menu dropdown-menu-right" uib-dropdown-menu role="menu" aria-labelledby="single-button">\n' +
    '                <li role="menuitem">\n' +
    '                    <a href download-file file-info-id="item.id">\n' +
    '                        <i class="icon icon_download-2 hidden-sm">&nbsp;</i>\n' +
    '                        Download\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '                <li role="menuitem">\n' +
    '                    <a href rename-file on-object="item"> Rename </a>\n' +
    '                </li>\n' +
    '                <li role="menuitem" class="hidden" show-if-is-admin show-if-is-owner="item.user_id">\n' +
    '                    <a href permission-file on-object="item">\n' +
    '                        Edit Permissions\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '                <li role="menuitem" class="hidden" show-if-can-move="list.can_move">\n' +
    '                    <a href move-directory on-object="item" type="file" move-id="list.can_move">\n' +
    '                        Move File {[{item.id}]}\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '                <li class="divider"></li>\n' +
    '                <li role="menuitem">\n' +
    '                    <a href delete-file on-object="item">\n' +
    '                        <span class="text-danger"> Delete </span>\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '</td>'
  );
  $templateCache.put('common/directives/disk-row-head.html',
    '<th>Name</th>\n' +
    '<th></th>\n' +
    '<th style="width: 180px">Time Added</th>\n' +
    '<th class="text-center">Tags</th>\n' +
    '<th style="width: 30px"></th>'
  );
  $templateCache.put('common/directives/disk-tile-directory.html',
    '<div jump-to-directory dir-id="item.id" dir-name="item.name">\n' +
    '    <div class="thumb">\n' +
    '        <img width="100%" ng-src="/img/folder-thumb/{[{ item.name | slug }]}.png" onerror="this.src=\'/img/folder-thumb/unknown.png\'">\n' +
    '        <br>\n' +
    '    </div>\n' +
    '    {[{ item.name }]}\n' +
    '</div>'
  );
  $templateCache.put('common/directives/download-directory.modal.html',
    '<!--<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>-->\n' +
    '\n' +
    '<h3>\n' +
    '    Preparing...\n' +
    '</h3>\n' +
    '\n' +
    '<hr>\n' +
    '\n' +
    '<div class="spinner mb-xs-20">\n' +
    '</div>\n' +
    '\n' +
    '<p>\n' +
    '    The directory is being packaged into a ZIP file in the background, which will take some time. Your download will begin shortly.\n' +
    '</p>\n' +
    '\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('common/directives/move.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<div class="login-content col-md-12">\n' +
    '    <h3 class="primary">Move Directory</h3>\n' +
    '    <hr>\n' +
    '    <form class="">\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12" ng-if="modalCtrl.onObject">\n' +
    '                <p>Select Destination</p>\n' +
    '                <ul class="move-list">\n' +
    '                    <move-directory-list items="modalCtrl.directories" moving="{[{modalCtrl.onObject.id}]}"></move-directory-list>\n' +
    '                </ul>\n' +
    '                <div class="form-group" ng-show="modalCtrl.moveTo">\n' +
    '                    <p>\n' +
    '                        From: {[{ modalCtrl.fullPath }]}\n' +
    '                    </p>\n' +
    '                    <p>\n' +
    '                        To: {[{ modalCtrl.moveToFullPath }]} / {[{ modalCtrl.onObject.name }]}\n' +
    '                    </p>\n' +
    '                    <sup>Note: please check directory permissions in new location.</sup><br>\n' +
    '                    <a href class="btn btn-primary" ng-click="modalCtrl.submit()">\n' +
    '                        Submit\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</div>\n' +
    '\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('common/directives/new-directory.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<div class="login-content col-md-12">\n' +
    '    <h3 class="primary">New Folder</h3>\n' +
    '    <p>\n' +
    '        {[{ modalCtrl.onObject.name }]}\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="alert alert-warning" ng-show="modalCtrl.notification.text" ng-bind-html="modalCtrl.notification.text | nl2br">\n' +
    '    </div>\n' +
    '\n' +
    '    <hr>\n' +
    '    <form class="">\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12" ng-if="modalCtrl.onObject">\n' +
    '                <div class="form-group">\n' +
    '                    <input type="text" id="name" ng-model="modalCtrl.name" chars="folder" class="form-control" placeholder="Input new folder name" autofocus>\n' +
    '                </div>\n' +
    '                <div class="form-group">\n' +
    '                    <a href class="btn btn-primary" ng-click="modalCtrl.create()">\n' +
    '                        Create\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</div>\n' +
    '\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('common/directives/permission.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<div class="login-content col-md-12">\n' +
    '    <h3 class="primary">Permissions</h3>\n' +
    '    <p>\n' +
    '        {[{ modalCtrl.onObject.name }]}\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="alert alert-warning" ng-show="modalCtrl.notification.text" ng-bind-html="modalCtrl.notification.text | nl2br">\n' +
    '    </div>\n' +
    '\n' +
    '    <hr>\n' +
    '    <form class="">\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12" ng-if="modalCtrl.onObject">\n' +
    '                <div class="form-check">\n' +
    '                    <input class="theme-radio" id="radio_inherit" type="radio" ng-value="false" name="enabled" ng-model="modalCtrl.enabled">\n' +
    '                    <label for="radio_inherit">\n' +
    '                        Inherit permission from parent folder (Default)\n' +
    '                    </label>\n' +
    '                </div>\n' +
    '                <div class="form-check">\n' +
    '                    <input class="theme-radio" id="radio_custom" type="radio" ng-value="true" name="enabled" ng-model="modalCtrl.enabled">\n' +
    '                    <label for="radio_custom">\n' +
    '                        Custom permission\n' +
    '                    </label>\n' +
    '                </div>\n' +
    '                <div class="form-group group-list">\n' +
    '                    <table class="table" ng-show="modalCtrl.enabled" style="background: #FFF; font-size: 0.9em">\n' +
    '                        <tr>\n' +
    '                            <th>\n' +
    '                                Group\n' +
    '                            </th>\n' +
    '                            <th style="width: 30%">\n' +
    '                                Permission\n' +
    '                            </th>\n' +
    '                        </tr>\n' +
    '                        <tr class="active">\n' +
    '                            <td>\n' +
    '                                Administrators\n' +
    '                            </td>\n' +
    '                            <td>\n' +
    '                                <sm-dropdown class="selection disabled" default-text="\'Read & Write\'"></sm-dropdown>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                        <tr class="active">\n' +
    '                            <td>\n' +
    '                                Owner\n' +
    '                            </td>\n' +
    '                            <td>\n' +
    '                                <sm-dropdown class="selection disabled" default-text="\'Read & Write\'"></sm-dropdown>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                        <tr ng-repeat="group in modalCtrl.groups">\n' +
    '                            <td>\n' +
    '                                {[{ group.name }]}\n' +
    '                            </td>\n' +
    '                            <td>\n' +
    '                                <sm-dropdown class="selection" id="permission_dropdown_{[{group.id}]}" model="modalCtrl.pickedPermission[group.id]" items="modalCtrl.permissionTypes" label="item.label" value="item.value" default-text="\'Inherit Parent\'"></sm-dropdown>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                        <tr class="warning">\n' +
    '                            <td>\n' +
    '                                Everyone\n' +
    '                            </td>\n' +
    '                            <td>\n' +
    '                                <sm-dropdown class="selection" model="modalCtrl.pickedPermission[0]" items="modalCtrl.permissionTypes" label="item.label" value="item.value" default-text="\'Inherit Parent\'"></sm-dropdown>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                    </table>\n' +
    '                </div>\n' +
    '                <div class="form-group">\n' +
    '                    <a href class="btn btn-primary" ng-click="modalCtrl.submit()">\n' +
    '                        Submit\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</div>\n' +
    '\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('common/directives/preview.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '<div class="popup-preview">\n' +
    '    <div class="preview-image">\n' +
    '        <img class="thumb" ng-src="/thumbnail/file/seed/{[{ modalCtrl.onObject.id }]}">\n' +
    '    </div>\n' +
    '    <div class="detail">\n' +
    '        <h3>\n' +
    '            {[{ modalCtrl.onObject.name }]}\n' +
    '        </h3>\n' +
    '        <dl>\n' +
    '            <dt>Size</dt>\n' +
    '            <dd>{[{ modalCtrl.onObject.cloud_file.file_size | filesize }]}</dd>\n' +
    '            <dt>Uploaded by</dt>\n' +
    '            <dd>{[{ modalCtrl.onObject.user.name }]}</dd>\n' +
    '            <dt>Date uploaded</dt>\n' +
    '            <dd>\n' +
    '                {[{ modalCtrl.onObject.created_at | amUtc | amDateFormat:"DD/MM/YYYY h:mma" }]}\n' +
    '            </dd>\n' +
    '            <dt>Tags <a href class="pull-right hidden" ng-click="modalCtrl.openTagsModal()">Edit</a></dt>\n' +
    '            <dd>\n' +
    '                <span ng-repeat="tag in modalCtrl.onObject.tags" class="label label-default mr-10" ng-class="{\'label-red\': tag.level == 0}">\n' +
    '                    {[{ tag.name }]}\n' +
    '                </span>\n' +
    '                <span ng-if="modalCtrl.onObject.tags.length == 0">\n' +
    '                    -\n' +
    '                </span>\n' +
    '            </dd>\n' +
    '        </dl>\n' +
    '\n' +
    '\n' +
    '        <div class="tools text-center">\n' +
    '            <a class="btn btn-macaroni btn-sm" href download-file file-info-id="modalCtrl.onObject.id">\n' +
    '                <i class="icon icon_download-2 hidden-sm">&nbsp;</i>\n' +
    '                Download\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('common/directives/rename.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<div class="login-content col-md-12">\n' +
    '    <h3 class="primary">Rename</h3>\n' +
    '    <p>\n' +
    '        {[{ modalCtrl.onObject.name }]}\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="alert alert-warning" ng-show="modalCtrl.notification.text" ng-bind-html="modalCtrl.notification.text | nl2br">\n' +
    '    </div>\n' +
    '\n' +
    '    <hr>\n' +
    '    <form class="">\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12" ng-if="modalCtrl.onObject">\n' +
    '                <div class="form-group">\n' +
    '                    <input type="text" id="name" ng-model="modalCtrl.name" chars="folder" class="form-control" placeholder="Input new name" autofocus>\n' +
    '                </div>\n' +
    '                <div class="form-group">\n' +
    '                    <a href class="btn btn-primary" ng-click="modalCtrl.rename()">\n' +
    '                        Rename\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</div>\n' +
    '\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('common/directives/share-object.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<div class="login-content col-md-12">\n' +
    '    <h3 class="primary">Share</h3>\n' +
    '    <p>\n' +
    '        {[{ modalCtrl.shareObject.name }]}\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="alert alert-warning" ng-show="modalCtrl.notification.text" ng-bind-html="modalCtrl.notification.text | nl2br">\n' +
    '    </div>\n' +
    '\n' +
    '    <hr>\n' +
    '    <form class="">\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12" ng-if="!modalCtrl.shareObject.sharing_link.link">\n' +
    '                <div class="form-group">\n' +
    '                    <i class="glyphicon glyphicon-link"></i> No link created yet\n' +
    '                </div>\n' +
    '                <div class="form-group">\n' +
    '                    <a href class="btn btn-primary btn-sm" ng-disabled="modalCtrl.isLoading" ng-click="modalCtrl.startSharing()">\n' +
    '                        <i class="icon icon_share">&nbsp;</i>\n' +
    '                        Start Sharing\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="col-xs-12" ng-if="modalCtrl.shareObject.sharing_link.link">\n' +
    '\n' +
    '                <div class="form-group">\n' +
    '                    <div class="input-group">\n' +
    '                        <div class="input-group-addon">\n' +
    '                            <i class="glyphicon glyphicon-link"></i>\n' +
    '                        </div>\n' +
    '                        <input type="text" id="link" ng-value="modalCtrl.getAbsSharingLink()" class="form-control" readonly="readonly">\n' +
    '                        <div class="input-group-addon hidden-xs hidden-sm">\n' +
    '                            <a href class="btn btn-link btn-sm" uib-popover="Copied!" popover-trigger="\'click\'" copy-to-clipboard input-element="#link">Copy URL</a>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="form-group" ng-show="!modalCtrl.showSettings">\n' +
    '                    <hr>\n' +
    '                    <span class="text-muted" style="padding-top: 5px;display: inline-block">\n' +
    '                        Shared link will expire <span am-time-ago="modalCtrl.shareObject.sharing_link.expiry_at"></span>\n' +
    '                    </span>\n' +
    '                    <a class="btn btn-macaroni btn-sm pull-right" href ng-click="modalCtrl.showSettings = true">\n' +
    '                        <i class="glyphicon glyphicon-calendar"></i>\n' +
    '                        Change Expiry Date\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '\n' +
    '                <div ng-if="modalCtrl.showSettings">\n' +
    '                    <hr>\n' +
    '                    <div class="form-group hidden">\n' +
    '                        <div class="input-group col-xs-12">\n' +
    '                            <input type="text" ng-model="modalCtrl.settings.description" class="form-control" placeholder="Description">\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="form-group">\n' +
    '                        <div class="input-group col-xs-12">\n' +
    '                            <div class="input-group-addon">\n' +
    '                                <i class="glyphicon glyphicon-calendar"></i>\n' +
    '                            </div>\n' +
    '                            <input type="text" class="form-control" ng-focus="datePicker1.opened = true" placeholder="Expiry Date" uib-datepicker-popup="dd/MM/yyyy" ng-model="modalCtrl.settings.expiry_at" ng-value="modalCtrl.shareObject.sharing_link.expiry_at | amDateFormat:\'DD/MM/YYYY\'" is-open="datePicker1.opened" datepicker-options="modalCtrl.datePickerConfig" close-text="Close" readonly="readonly">\n' +
    '                            <span class="input-group-btn">\n' +
    '                                <button type="button" class="btn btn-link" ng-click="datePicker1.opened = true"><i class="glyphicon glyphicon-calendar"></i></button>\n' +
    '                            </span>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="form-group">\n' +
    '                        <a href class="btn btn-primary btn-sm" ng-click="modalCtrl.saveSettings()">\n' +
    '                            <i class="icon icon_tick">&nbsp;</i>\n' +
    '                            Save\n' +
    '                        </a>\n' +
    '                        <a href class="btn btn-macaroni btn-sm" ng-click="modalCtrl.showSettings = false">\n' +
    '                            Cancel\n' +
    '                        </a>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="form-group" ng-show="!modalCtrl.showSettings">\n' +
    '                    <!--<a href class="btn btn-macaroni btn-sm" ng-click="modalCtrl.showSettings = true">\n' +
    '                        <i class="icon icon_settings">&nbsp;</i>\n' +
    '                        Settings\n' +
    '                    </a>-->\n' +
    '                    <!--<a href class="btn btn-macaroni btn-sm" ng-click="modalCtrl.stopSharing()">\n' +
    '                        <i class="icon icon_lock-2">&nbsp;</i>\n' +
    '                        Stop Sharing\n' +
    '                    </a>-->\n' +
    '                    <a href class="btn btn-primary btn-sm" ng-click="$dismiss()">\n' +
    '                        Done\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</div>\n' +
    '\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('common/directives/share-row-directory.html',
    '<td jump-to-share-directory sharing-link="sharingLink" dir-id="item.id" dir-name="item.name" class="thumb">\n' +
    '    <img class="thumb" src="/img/file-extensions/folder.png">\n' +
    '</td>\n' +
    '<td jump-to-share-directory sharing-link="sharingLink" dir-id="item.id" dir-name="item.name">\n' +
    '    {[{ item.name }]}\n' +
    '</td>\n' +
    '<td>\n' +
    '    {[{ item.created_at | amUtc | amDateFormat:"DD/MM/YYYY h:mma" }]}\n' +
    '    <br>\n' +
    '    <span class="text-muted">by {[{ item.user.name }]}</span>\n' +
    '</td>\n' +
    '<td style="width: 30px">\n' +
    '    <ul class="links">\n' +
    '        <li class="btn-group show-on-hover" uib-dropdown>\n' +
    '           <!-- <a id="single-button" type="button" class="btn btn-macaroni btn-sm" uib-dropdown-toggle ng-disabled="disabled">\n' +
    '                More <span class="caret"></span>\n' +
    '            </a>\n' +
    '            <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">\n' +
    '                &lt;!&ndash;<li role="menuitem">\n' +
    '                    <a href download-file file-info-id="item.id" sharing-link="sharingLink">\n' +
    '                        <i class="icon icon_download-2 hidden-sm">&nbsp;</i>\n' +
    '                        Download\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '                <li role="menuitem"><a href>Rename</a></li>\n' +
    '                <li class="divider"></li>&ndash;&gt;\n' +
    '            </ul>-->\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '</td>'
  );
  $templateCache.put('common/directives/share-row-file.html',
    '<td class="thumb"> <!--download-file file-info-id="item.id" trigger-action="dblClick"-->\n' +
    '    <img class="thumb" ng-src="/thumbnail/file/sm/{[{ item.id }]}/{[{ sharingLink }]}">\n' +
    '</td>\n' +
    '<td>\n' +
    '    {[{ item.name }]}\n' +
    '    <br>\n' +
    '    <small class="text-muted">{[{ item.cloud_file.file_size | filesize }]}</small>\n' +
    '</td>\n' +
    '<td>\n' +
    '    {[{ item.created_at | amUtc | amDateFormat:"DD/MM/YYYY h:mma" }]}\n' +
    '    <br>\n' +
    '    <span class="text-muted">by {[{ item.user.name }]}</span>\n' +
    '</td>\n' +
    '<td style="width: 30px">\n' +
    '    <ul class="links">\n' +
    '        <li class="btn-group" uib-dropdown>\n' +
    '            <a href download-file file-info-id="item.id" sharing-link="sharingLink" class="btn btn-macaroni btn-sm">\n' +
    '                <i class="icon icon_download-2 hidden-sm">&nbsp;</i>\n' +
    '                Download\n' +
    '            </a>\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '</td>'
  );
  $templateCache.put('common/directives/share-row-head.html',
    '<th>Name</th>\n' +
    '<th></th>\n' +
    '<th style="width: 180px">Time Added</th>\n' +
    '<th style="width: 30px"></th>'
  );
  $templateCache.put('common/directives/subscribe.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '	<!-- Begin MailChimp Signup Form -->\n' +
    '	<div id="mc_embed_signup">\n' +
    '		<form action="https://lg.us13.list-manage.com/subscribe/post?u=18b71131dd4b48429dda37069&amp;id=afe0457995" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>\n' +
    '			<h3 class="primary">Subscribe to our mailing list</h3>\n' +
    '\n' +
    '			<div id="mce-responses" class="clearfix">\n' +
    '				<div class="response alert alert-danger" id="mce-error-response" style="display:none"></div>\n' +
    '				<div class="response alert alert-success" id="mce-success-response" style="display:none"></div>\n' +
    '			</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->\n' +
    '\n' +
    '			<div class="row" id="mc_embed_signup_scroll">\n' +
    '				<div class="col-sm-6">\n' +
    '					<div class="form-group mc-field-group">\n' +
    '						<input type="email" value="" name="EMAIL" class="form-control required email" id="mce-EMAIL" placeholder="Email Address" required>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="col-sm-6">\n' +
    '					<div class="form-group mc-field-group">\n' +
    '					<input type="text" value="" name="FNAME" class="form-control" id="mce-FNAME" placeholder="First Name" required>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="col-sm-6">\n' +
    '					<div class="form-group mc-field-group">\n' +
    '					<input type="text" value="" name="LNAME" class="form-control" id="mce-LNAME" placeholder="Last Name" required>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="col-sm-6">\n' +
    '					<div class="form-group mc-field-group">\n' +
    '					<select name="STATE" class="form-control" id="mce-STATE" required>\n' +
    '						<option value="" disabled="disabled" selected="selected">-- Select State --</option>\n' +
    '						<option value="Australian Capital Territory">Australian Capital Territory</option>\n' +
    '						<option value="New South Wales">New South Wales</option>\n' +
    '						<option value="Northern Territory">Northern Territory</option>\n' +
    '						<option value="Queensland">Queensland</option>\n' +
    '						<option value="South Australia">South Australia</option>\n' +
    '						<option value="Tasmania">Tasmania</option>\n' +
    '						<option value="Victoria">Victoria</option>\n' +
    '						<option value="Western Australia">Western Australia</option>\n' +
    '					</select>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<input type="hidden" name="SOURCE" value="B2B Portal">\n' +
    '\n' +
    '				<div class="col-sm-12 col-checkbox mc-field-group">\n' +
    '					<input type="checkbox" name="TERMS" class="theme-checkbox" required id="__terms">\n' +
    '					<label for="__terms" class="required">\n' +
    '						I accept LG Australia\'s <a href="https://www.lg.com/au/privacy" class="link" target="_blank">Privacy Policy</a>\n' +
    '					</label>\n' +
    '				</div>\n' +
    '				<div style="position: absolute; left: -5000px" aria-hidden="true"><input type="text" name="b_18b71131dd4b48429dda37069_afe0457995" tabindex="-1" value=""></div>\n' +
    '\n' +
    '			</div>\n' +
    '\n' +
    '			<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="btn btn-primary">\n' +
    '		</form>\n' +
    '	</div>\n' +
    '	<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>\n' +
    '	<script type="text/javascript">\n' +
    '		(function($) {\n' +
    '		    window.fnames = new Array();\n' +
    '		    window.ftypes = new Array();\n' +
    '		    fnames[0]=\'EMAIL\';\n' +
    '		    ftypes[0]=\'email\';\n' +
    '		    fnames[1]=\'FNAME\';\n' +
    '		    ftypes[1]=\'text\';\n' +
    '		    fnames[2]=\'LNAME\';\n' +
    '		    ftypes[2]=\'text\';\n' +
    '		    fnames[3]=\'ADDRESS\';\n' +
    '		    ftypes[3]=\'address\';\n' +
    '		    fnames[4]=\'PHONE\';\n' +
    '		    ftypes[4]=\'phone\';\n' +
    '		    fnames[5]=\'SOURCE\';\n' +
    '		    ftypes[5]=\'text\';\n' +
    '			fnames[6]=\'STATE\';\n' +
    '			ftypes[6]=\'dropdown\';\n' +
    '            fnames[7]=\'TERMS\';\n' +
    '            ftypes[7]=\'checkbox\';\n' +
    '		}(jQuery));\n' +
    '		var $mcj = jQuery.noConflict(true);\n' +
    '	</script>\n' +
    '	<!--End mc_embed_signup-->'
  );
  $templateCache.put('common/directives/tag-object.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<div class="login-content col-md-12">\n' +
    '    <h3 class="primary">Tag</h3>\n' +
    '    <p>\n' +
    '        {[{ modalCtrl.tagObject.name }]}\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="alert alert-warning" ng-show="modalCtrl.notification.text" ng-bind-html="modalCtrl.notification.text | nl2br">\n' +
    '    </div>\n' +
    '\n' +
    '    <hr>\n' +
    '    <form class="">\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '\n' +
    '                <!-- Main Tags Display -->\n' +
    '                <div class="form-group" ng-if="!modalCtrl.editingMainTags">\n' +
    '                    <p>\n' +
    '                        <strong>Selected Main Tags:</strong>\n' +
    '                    </p>\n' +
    '                    <p>\n' +
    '                        <span class="label label-success mr-10" ng-repeat="(key, value) in modalCtrl.mainTags" ng-show="modalCtrl.selectedMainTags | contains: value.value">\n' +
    '                            {[{ value.label }]}\n' +
    '                        </span>\n' +
    '                    </p>\n' +
    '                    <p>\n' +
    '                        <a href class="btn btn-macaroni btn-sm" ng-disabled="modalCtrl.isLoading" ng-click="modalCtrl.editingMainTags = true">\n' +
    '                            Edit\n' +
    '                        </a>\n' +
    '                    </p>\n' +
    '                </div>\n' +
    '\n' +
    '                <!-- Main Tags Edit -->\n' +
    '                <div ng-if="modalCtrl.editingMainTags">\n' +
    '                    <div class="form-group">\n' +
    '                        <sm-dropdown class="fluid multiple search selection" model="modalCtrl.selectedMainTags" items="modalCtrl.mainTags" label="item.label" value="item.value" default-text="\'Add main tags\'"></sm-dropdown>\n' +
    '                    </div>\n' +
    '                    <div class="form-group">\n' +
    '                        <a href class="btn btn-primary btn-sm" ng-disabled="modalCtrl.isLoading" ng-click="modalCtrl.changeMainTags()">\n' +
    '                            Next\n' +
    '                        </a>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="form-group" ng-if="modalCtrl.selectedMainTags.length > 0 && modalCtrl.editingMainTags == false">\n' +
    '                    <p>\n' +
    '                        <strong>Select Sub Tags:</strong>\n' +
    '                    </p>\n' +
    '                    <sm-dropdown class="fluid multiple search selection" model="modalCtrl.selectedSubTags" items="modalCtrl.subTags" label="item.label" value="item.value" default-text="\'Add sub tags\'"></sm-dropdown>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="form-group" ng-if="modalCtrl.editingMainTags == false">\n' +
    '                    <a href class="btn btn-primary btn-sm" ng-disabled="modalCtrl.isLoading" ng-click="modalCtrl.save()">\n' +
    '                        <i class="icon icon_tick">&nbsp;</i>\n' +
    '                        Save\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</div>\n' +
    '\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('config/directives/alert.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '<div class="">\n' +
    '    <h3 class="primary" ng-show="modalCtrl.title" ng-bind-html="modalCtrl.title | nl2br"></h3>\n' +
    '    <hr>\n' +
    '    <p ng-show="modalCtrl.message" ng-bind-html="modalCtrl.message | nl2br"></p>\n' +
    '</div>\n' +
    '<button type="button" class="btn btn-primary btn-sm" ng-click="$close()">OK</button>\n' +
    '<div class="clearfix"></div>'
  );
  $templateCache.put('config/directives/confirm.modal.html',
    '<div class="modal-header">\n' +
    '    <button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '    <h4 class="modal-title" ng-bind-html="modalCtrl.message | nl2br"></h4>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '    <div class="confirmcontent">\n' +
    '        <p class="intro"></p>\n' +
    '    </div>\n' +
    '    <button type="button" class="btn btn-primary btn-sm" ng-click="$close()">Yes</button>\n' +
    '    <button type="button" class="btn btn-macaroni btn-sm" ng-click="$dismiss()">No</button>\n' +
    '</div>'
  );
  $templateCache.put('config/directives/popup.modal.html',
    '<div class="modal-body">\n' +
    '	<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '    <div class="popupcontent clearfix">\n' +
    '        <div class="col-sm-6 left-side">\n' +
    '        	<p class="text-line">Want to wipe</p>\n' +
    '        	<p class="prize-line">$2000</p> \n' +
    '        	<p class="text-line">off your credit card?</p>\n' +
    '        </div>\n' +
    '         <div class="col-sm-6 right-side">\n' +
    '        	<p>Simply ask our community of industry experts a question to be in the running to win!</p>\n' +
    '        	<br>\n' +
    '        	<button class="cta-button" ng-click="$close()">Get Started</button>\n' +
    '        	<p class="terms">Promotion open to new and existing users, authorised under NSW Permit No. LTPS/17/13762. See <a href="https://www.simplyaskit.com.au/blog/promotion-ask-a-question-and-win/" target="_blank">Promotion terms</a> for more information.</p>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>'
  );
  $templateCache.put('config/directives/subscribe.modal.html',
    '<div class="modal-body">\n' +
    '	<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '	<!-- Begin MailChimp Signup Form -->\n' +
    '	<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">\n' +
    '	<style type="text/css">\n' +
    '		#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }\n' +
    '		/* Add your own MailChimp form style overrides in your site stylesheet or in this style block.\n' +
    '           We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n' +
    '	</style>\n' +
    '	<div id="mc_embed_signup">\n' +
    '		<form action="https://lg.us13.list-manage.com/subscribe/post?u=18b71131dd4b48429dda37069&amp;id=afe0457995" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>\n' +
    '			<div id="mc_embed_signup_scroll">\n' +
    '				<h2>Subscribe to our mailing list</h2>\n' +
    '				<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>\n' +
    '				<div class="mc-field-group">\n' +
    '					<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>\n' +
    '					</label>\n' +
    '					<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">\n' +
    '				</div>\n' +
    '				<div class="mc-field-group">\n' +
    '					<label for="mce-FNAME">First Name </label>\n' +
    '					<input type="text" value="" name="FNAME" class="" id="mce-FNAME">\n' +
    '				</div>\n' +
    '				<div class="mc-field-group">\n' +
    '					<label for="mce-LNAME">Last Name </label>\n' +
    '					<input type="text" value="" name="LNAME" class="" id="mce-LNAME">\n' +
    '				</div>\n' +
    '				<div class="mc-field-group">\n' +
    '					<label for="mce-STATE">State </label>\n' +
    '					<select name="STATE" class="" id="mce-STATE">\n' +
    '						<option value="">-- Select State --</option>\n' +
    '						<option value="Australian Capital Territory">Australian Capital Territory</option>\n' +
    '						<option value="New South Wales">New South Wales</option>\n' +
    '						<option value="Northern Territory">Northern Territory</option>\n' +
    '						<option value="Queensland">Queensland</option>\n' +
    '						<option value="South Australia">South Australia</option>\n' +
    '						<option value="Tasmania">Tasmania</option>\n' +
    '						<option value="Victoria">Victoria</option>\n' +
    '						<option value="Western Australia">Western Australia</option>\n' +
    '\n' +
    '					</select>\n' +
    '				</div>\n' +
    '				<input type="hidden" name="SOURCE" value="B2B Portal">\n' +
    '				<div id="mce-responses" class="clear">\n' +
    '					<div class="response" id="mce-error-response" style="display:none"></div>\n' +
    '					<div class="response" id="mce-success-response" style="display:none"></div>\n' +
    '				</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->\n' +
    '				<div style="position: absolute; left: -5000px" aria-hidden="true"><input type="text" name="b_18b71131dd4b48429dda37069_afe0457995" tabindex="-1" value=""></div>\n' +
    '				<div class="clear">\n' +
    '					<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</form>\n' +
    '	</div>\n' +
    '	<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]=\'EMAIL\';ftypes[0]=\'email\';fnames[1]=\'FNAME\';ftypes[1]=\'text\';fnames[2]=\'LNAME\';ftypes[2]=\'text\';fnames[3]=\'ADDRESS\';ftypes[3]=\'address\';fnames[4]=\'PHONE\';ftypes[4]=\'phone\';fnames[5]=\'SOURCE\';ftypes[5]=\'text\';fnames[6]=\'STATE\';ftypes[6]=\'dropdown\';}(jQuery));var $mcj = jQuery.noConflict(true);</script>\n' +
    '	<!--End mc_embed_signup-->\n' +
    '\n' +
    '</div>'
  );
  $templateCache.put('user/directives/forgot-password.modal.html',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close" ng-click="$dismiss()"><span aria-hidden="true">&times;</span></button>\n' +
    '\n' +
    '<form class="register-content" ng-submit="modalCtrl.submit()">\n' +
    '    <h3 class="primary">Forgot Your Password?</h3>\n' +
    '    <p>\n' +
    '        <strong>Forgot Password</strong>\n' +
    '    </p>\n' +
    '\n' +
    '    <div class="alert alert-warning" ng-show="modalCtrl.message" ng-bind-html="modalCtrl.message | nl2br">\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-sm-12">\n' +
    '            <div class="form-group" ng-class="{\'has-error\' : modalCtrl.errors.email}">\n' +
    '                <input type="email" ng-disabled="modalCtrl.isLoading" ng-model="modalCtrl.form.email" class="form-control" placeholder="Email" required>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <input type="submit" ng-disabled="modalCtrl.isLoading" class="btn btn-primary btn-sm less-wide" value="Submit" ng-value="modalCtrl.isLoading ? \'Loading\' : \'Submit\'">\n' +
    '</form>'
  );
}]);

(function () {
    'use strict';

    angular.module('app.apiModule', [])
        .config(function(){
        }).run(function($http, authService){
            $http.defaults.headers.common.Authorization = 'Bearer '+ authService.getApiToken();
        });
})();
(function () {
'use strict';

angular.module('app.apiModule').factory('apiDiskService', ['$http', '$q', 'Upload', apiDiskService]);
    function apiDiskService($http, $q, Upload) {

        var service = {};
        
        service.downloadFiles = function(ids) {
            var deferred = $q.defer();
            
            // Send an array of file IDs to the backend for downloading
            $http.post(config.app_url + '/api/files/download', { ids: ids }).then(function(result) {
                deferred.resolve(result.data);
            }, function(err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };

        service.downloadFile = function(id) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/file/' + id + '/download').then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.deleteFile = function (id) {
            let deferred = $q.defer();
            let endpoint = '/api/file/' + id + '/delete';
            $http.post(config.app_url + endpoint).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.uploadFile = function(id, file) {
            let endpoint = id ? '/api/directory/' + id + '/upload' : '/api/directory/upload';
        
            return Upload.upload({
                url: config.app_url + endpoint,
                data: {
                    path: file.path,
                    file: file
                }
            })
        };        

        service.getDirectory = function(id){
            var deferred = $q.defer();
            $http.get(config.app_url + '/api/directory/' + id).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.prepareDownloadingDirectory = function(id) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/directory/' + id + '/prepare-downloading').then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.downloadDirectory = function(id) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/directory/' + id + '/download').then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.newDirectory = function (parent_id, name) {
            let deferred = $q.defer();
            let endpoint = parent_id ? '/api/directory/' + parent_id + '/create' : '/api/directory/create';
            let params = {
                'name': name.trim()
            };
            $http.post(config.app_url + endpoint, params).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.deleteDirectory = function (id) {
            let deferred = $q.defer();
            let endpoint = '/api/directory/' + id + '/delete';
            $http.post(config.app_url + endpoint).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        // Rename file, Rename directory
        service.renameObject = function(type, id, name) {
            let deferred = $q.defer();
            // File
            let endpoint = '/api/file/' + id + '/rename';
            if (type === 'directory') {
                // Directory
                endpoint = '/api/directory/' + id + '/rename';
            }
            let params = {
                'name': name.trim()
            };
            $http.post(config.app_url + endpoint, params).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.updateObjectPermission = function(type, objectId, permissions){
            var deferred = $q.defer();
            let endpoint = '/api/' + type + '/' + objectId + '/permission';
            $http.post(config.app_url + endpoint, {
                'permissions': permissions
            }).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.getObjectPermission = function(type, objectId){
            var deferred = $q.defer();
            let endpoint = '/api/' + type + '/' + objectId + '/permission';
            $http.get(config.app_url + endpoint, {cache: true}).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.getSearchResult = function(keywords){
            var deferred = $q.defer();
            $http.get(config.app_url + '/api/search?keywords='+keywords).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.getMoveDirectories = function(id){
            var deferred = $q.defer();
            $http.get(config.app_url + '/api/move/directories/' + id).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        }

        service.moveDirectory = function(item, to) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/move/directory', {'item': item, 'to': to}).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        }

        service.moveFile = function(item, to) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/move/file', {'item': item, 'to': to}).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        }

        return service;

    }
})();

(function () {
'use strict';

angular.module('app.apiModule').factory('apiGroupService', ['$http', '$q', apiGroupService]);
    function apiGroupService($http, $q) {

        var service = {};
        service.getGroups = function(){
            var deferred = $q.defer();
            let endpoint = '/api/group';
            $http.get(config.app_url + endpoint).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        return service;

    }
})();

(function () {
'use strict';

angular.module('app.apiModule').factory('apiShareService', ['$http', '$q', apiShareService]);
    function apiShareService($http, $q) {

        var service = {};

        /**
         * @todo Password and expiry verifying
         */
        service.verify = function(link) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/share/'+link+'/verify').then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.update = function (link, params) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/share/' + link + '/edit', params).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.getFile = function(link){
            var deferred = $q.defer();
            $http.get(config.app_url + '/api/share/' + link + '/file').then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.downloadFile = function(id, link) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/share/' + link + '/file/' + id + '/download').then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        }

        service.getDirectory = function(link, id){
            var deferred = $q.defer();
            let endpoint = id ? '/api/share/' + link + '/directory/' + id : '/api/share/' + link + '/directory';
            $http.get(config.app_url + endpoint).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.startSharing = function (params) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/share/create', params).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.stopSharing = function (link) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/share/' + link + '/remove').then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        service.saveSettings = function (link, params) {
            var deferred = $q.defer();
            $http.post(config.app_url + '/api/share/' + link + '/update', params).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

        return service;

    }
})();

(function () {
'use strict';

angular.module('app.apiModule').factory('apiTagService', ['$http', '$q', apiTagService]);
    function apiTagService($http, $q) {

        var service = {};
        service.getAll = function(){
            var deferred = $q.defer();
            let endpoint = '/api/tag';
            $http.get(config.app_url + endpoint).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };
        service.updateObjectTags = function(objectId, type, tags){
            var deferred = $q.defer();
            let endpoint = '/api/' + type + '/' + objectId + '/tag';
            $http.post(config.app_url + endpoint, {
                'tags': tags
            }).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };
        return service;

    }
})();

(function () {
'use strict';

angular.module('app.apiModule').factory('apiUserService', ['$http', '$q', apiUserService]);
    function apiUserService($http, $q) {

        var service = {};

        service.login = function(params){
            return $http.post(config.app_url + '/login', params);
        };

        service.register = function(params){
            return $http.post(config.app_url + '/register', params);
        };

        service.getCurrentUser = function(params){
            return $http.get(config.app_url + '/api/user/current', params);
        };

        service.changePassword = function(params){
            return $http.post(config.app_url + '/api/user/change-password', params);
        };

        service.forgotPassword = function(params){
            return $http.post(config.app_url + '/password/email', params); // Laravel Auth path
        };

        return service;

    }
})();

(function () {
    'use strict';

    angular.module('app.authModule', ['app.configModule', 'app.apiModule', 'ui.bootstrap', 'app.userModule', 'app.templateModule'])
        .config([function(){

        }]).run(['$window', 'authModalService', 'authService', function($window, authModalService, authService){
            $window.authModals = authModalService;
        }]);
})();
(function () {
    'use strict';

    angular.module('app.authModule')
        .controller('LoginController', function ($scope, $http, $timeout, $interval, $location) {
            var vm = this;
            vm.isSubmitting = false;

            vm.loginForm = {
                email: '',
                login_password: null
            };

            vm.registrationForm = {
                name: '',
                email: '',
                company: ''
            };

            vm.notification = {
                text: "",
                category: null
            };

            function setNotificationText(message, category) {
                vm.notification.text = message;
                vm.notification.category = category;
            }

            vm.submitLoginForm = function() {
                vm.notification.text = "";
                userService.login(vm.loginForm).then(function(result){
                    if (!_.isUndefined(result.user.id)) {
                        vm.next();
                    } else {
                        setNotificationText("Couldn't log in with the Email and Password provided.");
                    }
                }, function(err){
                    console.warn(err);
                    if(!_.isUndefined(err.data.invalid)) {
                        setNotificationText(err.data.invalid, 'invalid');
                    } else if (!_.isUndefined(err.data.login_password)) {
                        setNotificationText(err.data.login_password[0], 'login_password');
                    } else if (!_.isUndefined(err.data.errors.email)) {
                        setNotificationText(err.data.errors.email[0]);
                    } else {
                        setNotificationText("There was an error logging you in. Please try again later.");
                    }
                });
            };

            vm.submitRegistrationForm = function() {
                vm.notification.text = "";
                vm.isSubmitting = true;
            
                var registrationData = {
                    name: vm.registrationForm.name,
                    email: vm.registrationForm.email,
                    company: vm.registrationForm.company
                };
            
                $http.post('/api/user-request', registrationData)
                    .then(function(response) {
                        // Registration was successful
                        console.log(response.data);
            
                        // Show a success message
                        vm.successMessage = "Registration successful!";
            
                        // Reset the form
                        vm.registrationForm = {
                            name: '',
                            email: '',
                            company: ''
                        };
            
                        // Mark the form as pristine (untouched and unchanged)
                        $scope.registrationForm.$setPristine();
                        vm.isSubmitting = false;
                    })
                    .catch(function(error) {
                        console.log(error);
                      
                         setNotificationText(error.data.message, 'invalid')
                         vm.isSubmitting = false;
                    });
            };

            // For popup mode only
            vm.register = function(type){
                $scope.$close({
                    authed: false,
                    action: 'register',
                    type: type
                });
            };
            // For popup mode only
            vm.forgot = function(){
                $scope.$close({
                    authed: false,
                    action: 'forgot'
                });
            };

            vm.next = function() {
                window.location.reload();
                /*$scope.$close({
                    authed: true,
                    user: result.user
                });*/
            }

        });
})();
(function () {
    'use strict';

    angular.module('app.authModule')
        .directive('logout', ['authService', function(authService){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        $event.preventDefault();
                        authService.destroyCurrentUser().then(function(result){
                            console.log('Destroy user', result);
                            document.getElementById('logout-form').submit();
                        }).catch(function(err){
                            window.location.reload();
                        });
                    });
                }
            };
        }]);
})();
(function () {
    'use strict';

    angular.module('app.authModule')
        .directive('openAuthDialog', ['authService', function(authService){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        $event.preventDefault();
                        // window.registerAccountType = parseInt(attrs.minimumAccountType) || 10;
                        window.returnUrl = attrs.returnUrl;
                        // console.log("set window.registerAccountType", window.registerAccountType);
                        authService.checkRegistration().then(function(){
                            if(attrs.returnUrl) {
                                window.location = attrs.returnUrl;
                            } else {
                                window.location.reload();
                            }
                        });
                    });
                }
            };
        }]);
})();
(function () {
    'use strict';

    angular.module('app.authModule')
        .directive('openChangePassword', ['authModalService', 'commonModals', function(authModalService, commonModals){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        $event.preventDefault();

                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);
                        authModalService.changePasswordModal().then(function(result){
                            window.location.reload();
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            };
        }]);
})();
(function () {
    'use strict';

    angular.module('app.authModule')
        .directive('openRegisterDialog', ['authModalService', 'commonModals', function(authModalService, commonModals){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        $event.preventDefault();

                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);
                        authModalService.openRegisterModal().then(function(result){
                            window.location.reload();
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            };
        }]);
})();
(function () {
'use strict';

angular.module('app.authModule').factory('authModalService', ['$q', '$uibModal', '$rootScope', 'userService', 'apiUserService', 'commonModals', authModalService]);
    function authModalService($q, $uibModal, $rootScope, userService, apiUserService, commonModals) {

        var service = {};

        service.openLogin = function(){
            var deferred = $q.defer();

            var loginModal = $uibModal.open({
                scope: $rootScope.$new(),
                windowClass: 'modal login',
                templateUrl: 'auth/directives/login.modal.html',
                controller: 'LoginController',
                controllerAs: 'modalCtrl',
                backdrop: false,
                animation: true,
            });

            loginModal.result.then(function(result){
                deferred.resolve(result);
            }, function(){
                deferred.reject();
            });

            return deferred.promise;
        };

        service.openRegisterModal = function(){
            var deferred = $q.defer();

            var registerModal = $uibModal.open({
                scope: $rootScope.$new(),
                windowClass: 'modal register',
                templateUrl: 'auth/directives/register.modal.html',
                controllerAs: 'modalCtrl',
                backdrop: false,
                animation: true,
                controller: function($scope){
                    var modalCtrl = this;

                    modalCtrl.notification = {
                        text: ''
                    };
                    function setNotificationText(message, category) {
                        modalCtrl.notification.text = message;
                        modalCtrl.notification.category = category;
                    }

                    modalCtrl.registerAccountType = parseInt(window.registerAccountType) || 10;

                    console.log("get modalCtrl.registerAccountType", modalCtrl.registerAccountType);
                    modalCtrl.form = {};

                    modalCtrl.errors = {};

                    function submitRegistration(params) {

                        modalCtrl.errors = {};
                        userService.register(modalCtrl.form).then(function(result){
                            if (!_.isUndefined(result.user.id)) {
                                $scope.$close(result.user);
                            } else {
                                setNotificationText('All fields were not filled in correctly');
                            }
                        }, function(err){
                            modalCtrl.errors = err.data;
                            var errStr = [];
                            if(_.isObject(err.data)){
                                Object.keys(err.data)
                                    .forEach(function eachKey(key) {
                                        errStr.push(err.data[key][0]);
                                    });
                                setNotificationText(errStr.join("\n"));
                            } else {
                                setNotificationText("Please try again later.");
                            }
                        });
                    };

                    modalCtrl.submit = function(){
                        submitRegistration(modalCtrl.form);
                    };

                }
            });

            registerModal.result.then(function(result){
                console.log('onHide arguments close', arguments);
                deferred.resolve(result);
            }, function(){
                console.log('onHide arguments dismiss', arguments);
                deferred.reject();
            });

            return deferred.promise;
        };

        service.changePasswordModal = function(){
            var deferred = $q.defer();
            var modal = $uibModal.open({
                scope: $rootScope.$new(),
                windowClass: 'modal',
                templateUrl: 'auth/directives/change-password-modal.modal.html',
                controllerAs: 'modalCtrl',
                backdrop: false,
                animation: true,
                // size: 'sm',
                controller: function($scope){
                    var modalCtrl = this;
                    modalCtrl.form = {
                        old_password: '',
                        new_password: ''
                    };
                    modalCtrl.other = {
                        new_password: ''
                    };
                    modalCtrl.notification = {
                        text: ''
                    };
                    function setNotificationText(message, category) {
                        modalCtrl.notification.text = message;
                        modalCtrl.notification.category = category;
                    }
                    function validate() {
                        if (modalCtrl.form.old_password == ''
                            || modalCtrl.form.new_password == ''
                            || modalCtrl.other.new_password == '') {
                            return $q.reject('You must fill in all fields');
                        }
                        if (modalCtrl.form.new_password !== modalCtrl.other.new_password) {
                            return $q.reject('Confirm password does not match');
                        }
                        return $q.resolve();
                    };
                    function callServer() {
                        var params = angular.copy(modalCtrl.form);
                        apiUserService.changePassword(params).then(function(result){
                            console.log(result.data);
                            if (result.data.success) {
                                $scope.$close();
                                // return commonModals.alertModal('Password updated successfully.', 'Password Updated');
                            } else {
                                setNotificationText(result.data.message || 'Unknown Error');
                                return $q.reject();
                            }
                        }, function(result) {
                            if (result.data.message) {
                                setNotificationText(result.data.message);
                            }
                        });
                    }
                    modalCtrl.submit = function(){
                        setNotificationText('');
                        validate().then(callServer, function(err){
                            setNotificationText(err)
                        });
                    };
                }
            });

            modal.result.then(function(result){
                console.log('onHide arguments close', arguments);
                deferred.resolve(result);
            }, function(){
                console.log('onHide arguments dismiss', arguments);
                deferred.reject();
            });

            return deferred.promise;
        };

        return service;

    }
})();

(function () {
'use strict';

angular.module('app.authModule')
    .factory('authService', ['userService', '$q', 'authModalService', 'commonModals', authService]);

    function authService(userService, $q, authModalService, commonModals) {

        var service = {};

        service.checkRegistration = function(){
            var deferred = $q.defer();
            var _self = this;
            angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);
            authModalService.openLogin().then(function(result){
                // got result from login modal, process it
                if (result.authed) {
                    // User chose to login and did so successfully, resolve deferred promise and exit wizard
                    deferred.resolve(result.user);
                    return $q.reject();
                } else {
                    return result;
                }
                return $q.reject();
            }).then(function(result){
                if(result.action == 'register') {
                    return authModalService.openRegisterModal().then(function(user){
                        return user;
                    });
                } else if (result.action == 'forgot') {
                    return userService.forgotPasswordModal().then(function(){
                        return commonModals.alertModal('A password reset link will be sent to this email if an account is registered under it.', 'Password Sent');
                    });
                }
                return $q.reject();
            }).then(function(user){
                // user registered successfully, we have reached the end of the wizard
                // deferred promise gets a user object
                deferred.resolve(user);
            }).catch(function(err){
                // do nothing at this stage, they've exited the wizard/closed a dialog.
                // Hopefully the 'deferred' promise has been resolved by whatever rejected this promise chain :)
                // will probably be used to close manually opened dialog backdrop when I implement it
                angular.element('.modal-backdrop').remove();
                deferred.reject(err);
            });

            return deferred.promise;
        };

        service.initCurrentUser = function() {
            if (window.config.user_id !== undefined) {
                return;
            }
            // Init user object
            console.warn('Fetching user info...');
            userService.getCurrentUser().then(function (result) {
                window.config.user_id = result.user_id;
                window.config.user_permissions = result.user_permissions;
                window.config.user_roles = result.user_roles;
            });
        }

        service.destroyCurrentUser = function() {
            var deferred = $q.defer();
            delete window.config.user_api_token;
            delete window.config.user_id;
            delete window.config.user_permissions;
            delete window.config.user_roles;
            deferred.resolve(window.config);
            return deferred.promise;
        }

        service.loggedIn = function() {
            return service.getApiToken().length > 0
        }

        service.getApiToken = function() {
            return window.config.user_api_token;
        }

        return service;

    }
})();

(function () {
    'use strict';

    angular.module('app.commonModule', [
        'app.configModule', 'app.apiModule', 'app.authModule',
        'ngTouch', 'ngAnimate', 'ui.bootstrap', 'ui.select',
        'ngFileUpload', 'ngSanitize', 'vcRecaptcha', 'angularMoment',
        'semantic-ui-dropdown'
    ])
        .config(function(){
        })
        .run(/*['$window', 'diskService', function($window, apiDiskService){
            $window.userService = userService;
        }]*/);

})();

(function () {
    "use strict";

    angular
        .module("app.commonModule")
        .controller(
            "DiskController",
            function (
                $scope,
                $http,
                $timeout,
                $interval,
                $rootScope,
                $location,
                $filter,
                $q,
                apiDiskService,
                authService,
                commonModals,
                authModalService,
                path
            ) {
                $location.search({});
                var vm = this;
                vm.isLoading = false;
                vm.notAllowed = false;
                vm.data = [];
                vm.breadcrumbs = [];
                vm.uploadingFiles = [];
                vm.loggedIn = authService.loggedIn();
                // vm.currentUser = authService.currentUser();
                vm.appName = window.config.app_name;

                vm.loadingStart = function () {
                    $rootScope.$emit("loadingStart", "disk");
                };

                vm.loadingEnd = function () {
                    $rootScope.$emit("loadingEnd", "disk");
                };

                let updateItemTruePermissions = function (
                    item,
                    readable,
                    writable
                ) {
                    item.trueReadable = readable;
                    item.trueWritable = writable;
                };

                let canMoveParent = function (parents) {
                    if (parents) {
                        if (
                            window.config.move_directories.indexOf(parents.id) >
                            -1
                        ) {
                            return parents.id;
                        } else {
                            return canMoveParent(parents.nested_parents);
                        }
                    }
                    return false;
                };

                let refreshObjectPermission = function (item, type) {
                    item.can_move = false;
                    if (window.config.move_directories) {
                        if (
                            window.config.move_directories.indexOf(item.id) > -1
                        ) {
                            item.can_move = item.id;
                        } else {
                            item.can_move = canMoveParent(item.nested_parents);
                        }
                    }
                    var defered = $q.defer();
                    if (window.config.user_roles.indexOf("admin") !== -1) {
                        // Admin always = RW
                        updateItemTruePermissions(item, true, true);
                        defered.resolve();
                    } else if (!item.id) {
                        // Root list, not admin = R
                        updateItemTruePermissions(item, true, false);
                        defered.resolve();
                    } else if (window.config.user_id == item.user_id) {
                        // Not root list, folder is created by user = RW
                        updateItemTruePermissions(item, true, true);
                        defered.resolve();
                    } else {
                        // Other conditions, calling AJAX for backend recursion check
                        apiDiskService.getObjectPermission(type, item.id).then(
                            function (result) {
                                updateItemTruePermissions(
                                    item,
                                    result.readable,
                                    result.writable
                                );
                                defered.resolve();
                            },
                            function (err) {
                                defered.reject();
                            }
                        );
                    }
                    return defered.promise;
                };

                vm.getDirectory = function (id) {
                    vm.loadingStart();
                    apiDiskService.getDirectory(id || "").then(
                        function (data) {
                            vm.loadingEnd();
                            // Refresh permissions for current list
                            refreshObjectPermission(data.list, "directory");
                            // Refresh permissions for its sub folders
                            angular.forEach(
                                data.list.nested_children,
                                function (item, key) {
                                    refreshObjectPermission(
                                        item,
                                        "directory"
                                    ).then(function () {
                                        // Remove items when trueReadable = false
                                        if (item.trueReadable === false) {
                                            delete data.list.nested_children[
                                                key
                                            ];
                                        }
                                    });
                                }
                            );
                            // Refresh permissions for its files
                            angular.forEach(
                                data.list.files,
                                function (item, key) {
                                    refreshObjectPermission(item, "file").then(
                                        function () {
                                            if (item.trueReadable === false) {
                                                delete data.list.files[key];
                                            }
                                        }
                                    );
                                }
                            );
                            vm.data = data;
                            vm.renderBreadcrumb();
                        },
                        function (result) {
                            vm.loadingEnd();
                            console.log(result);
                            switch (result.status) {
                                case 403:
                                    vm.breadcrumbs = [];
                                    vm.notAllowed = true;
                                    switch (result.data.code) {
                                        case "PASSWORD_EXPIRED":
                                            commonModals
                                                .alertModal(
                                                    result.data.message,
                                                    result.data.error
                                                )
                                                .then(function () {
                                                    angular
                                                        .element(
                                                            '<div class="modal-backdrop fade in"></div>'
                                                        )
                                                        .appendTo(
                                                            document.body
                                                        );
                                                    authModalService
                                                        .changePasswordModal()
                                                        .then(function (
                                                            result
                                                        ) {
                                                            window.location.reload();
                                                        })
                                                        .catch(function (err) {
                                                            window.location.reload();
                                                        });
                                                });
                                            break;
                                        case "ACCOUNT_LOCKED":
                                            window.location = result.data.url;
                                    }
                                    break;
                                case 423:
                                    if (result.data.url) {
                                        window.location = result.data.url;
                                    }
                                    break;
                            }
                        }
                    );
                };

                vm.renderBreadcrumb = function () {
                    vm.breadcrumbs = [];
                    var digDir = function (node) {
                        if (node) {
                            vm.breadcrumbs.unshift(node);
                        }
                        if (node && node.nested_parents) {
                            digDir(node.nested_parents);
                        }
                    };
                    digDir(vm.data.list.nested_parents);
                };

                $scope.$on("refreshDiskDirectory", function (event, data) {
                    let defaultDirId = path.dirId;
                    vm.getDirectory(defaultDirId);
                });

                vm.cleanupUploadingQueue = function () {
                    let count = $filter("uploadingNotFinished")(
                        vm.uploadingFiles
                    );
                    // If everything is finished, cleanup the queue.
                    console.log("Queue Length:", count);
                    if (count === 0) {
                        console.log("Queue Cleanup");
                        vm.uploadingFiles = [];
                    }
                };

                $scope.$watch(
                    function () {
                        return vm.uploadingFiles;
                    },
                    function () {
                        angular.forEach(
                            vm.uploadingFiles,
                            function (file, key) {
                                if (file.progress || file.started) {
                                    console.log("Skip", file);
                                    return; // Skip files in uploading
                                }
                                file.started = true;
                                setTimeout(function () {
                                    let handler = apiDiskService.uploadFile(
                                        path.dirId,
                                        file
                                    );
                                    handler.then(
                                        function (response) {
                                            // Successful
                                            console.log(response.data);
                                            file.done = true;
                                            $scope.$emit(
                                                "refreshDiskDirectory"
                                            );
                                            vm.cleanupUploadingQueue();
                                        },
                                        function (response) {
                                            if (response.status > 0) {
                                                console.log(
                                                    response.status +
                                                        ": " +
                                                        response.data
                                                );
                                                alert(response.data.message);
                                                file.done = true;
                                                $scope.$emit(
                                                    "refreshDiskDirectory"
                                                );
                                                vm.cleanupUploadingQueue();
                                                
                                                }
                                        },
                                        function (evt) {
                                            // Math.min is to fix IE which reports 200% sometimes
                                            file.progress = Math.min(
                                                100,
                                                parseInt(
                                                    (100.0 * evt.loaded) /
                                                        evt.total
                                                )
                                            );
                                        }
                                    );
                                    handler.xhr(function (xhr) {
                                        xhr.upload.addEventListener(
                                            "abort",
                                            function () {
                                                console.log("abort complete");
                                            },
                                            false
                                        );
                                    });
                                }, Math.floor(
                                    Math.random() * Math.floor(8000)
                                ));
                            }
                        );
                    }
                );

                $scope.uploadFiles = function (files, errFiles) {
                    //vm.uploadingFiles = _.uniqBy(vm.uploadingFiles.concat(files), 'name');
                    if (files.length == 0) {
                        commonModals.alertModal(
                            "Can't upload empty folder.",
                            "Upload"
                        );
                        return;
                    }
                    vm.uploadingFiles = vm.uploadingFiles.concat(files);
                    console.log("queue: ", vm.uploadingFiles);
                };

                $scope.$emit("refreshDiskDirectory");

               
    // Check if there are any selected files
    $scope.selectedFiles = {};

    
    // Check if any files are selected
    $scope.hasMultipleSelectedFiles = function () {
        return Object.keys($scope.selectedFiles).filter(key => $scope.selectedFiles[key]).length > 0;
    };
    // Update selected files state
              
    $scope.$on('updateSelectedFiles', function (event, data) {
        const { id, isSelected } = data; // Destructure id and isSelected flag
        if (isSelected) {
            $scope.selectedFiles[id] = true; // Mark file as selected
        } else {
            delete $scope.selectedFiles[id]; // Remove file if unselected
        }
    });
    $scope.downloadSelectedFiles = function () {
        const selectedFileIds = Object.keys($scope.selectedFiles).filter(id => $scope.selectedFiles[id]);

        if (selectedFileIds.length > 0) {
            apiDiskService.downloadFiles(selectedFileIds)
                .then(() => {
                    console.log("Download triggered for files:", selectedFileIds);
                })
                .catch(error => {
                    console.error("Error downloading files:", error);
                });
        } else {
            alert("No files selected for download.");
        }
    };
}
        )
        .filter("uploadingNotFinished", function () {
            return function (uploadingFiles) {
                var arr = [];
                angular.forEach(uploadingFiles, function (v) {
                    if (v.done !== true) {
                        arr.push(v);
                    }
                });
                return arr.length;
            };
        });
})();

(function () {
    'use strict';

    angular.module('app.commonModule')
        .controller('SearchBarController', function ($scope, $http, $timeout, $interval, $location) {
            var vm = this;
            vm.keywords = "";
            vm.search = function () {
                $location.path('/search').search('keywords', vm.keywords);
            }
        });
})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .controller('SearchController', function ($scope, $http, $timeout, $interval, $rootScope, $location, $q, apiDiskService, path) {

            var vm = this;
            vm.path = path;
            vm.data = [];
            vm.appName = window.config.app_name;

            vm.loadingStart = function() {
                $rootScope.$emit('loadingStart', 'disk');
                vm.data = [];
            }

            vm.loadingEnd = function() {
                $rootScope.$emit('loadingEnd', 'disk');
            }

            let updateItemTruePermissions = function(item, readable, writable) {
                item.trueReadable = readable;
                item.trueWritable = writable;
            };

            let refreshObjectPermission = function(item, type) {
                var defered = $q.defer();
                if (window.config.user_roles.indexOf('admin') !== -1) {
                    // Admin always = RW
                    updateItemTruePermissions(item, true, true);
                    defered.resolve();
                } else if (!item.id) {
                    // Root list, not admin = R
                    updateItemTruePermissions(item, true, false);
                    defered.resolve();
                } else if (window.config.user_id == item.user_id) {
                    // Not root list, folder is created by user = RW
                    updateItemTruePermissions(item, true, true);
                    defered.resolve();
                } else {
                    // Other conditions, calling AJAX for backend recursion check
                    apiDiskService.getObjectPermission(type, item.id).then(function (result) {
                        updateItemTruePermissions(item, result.readable, result.writable);
                        defered.resolve();
                    }, function (err) {
                        defered.reject();
                    });
                }
                return defered.promise;
            };

            vm.getSearchResult = function(keywords) {
                vm.loadingStart();

                apiDiskService.getSearchResult(keywords).then(function(data) {
                    vm.loadingEnd();
                    // Refresh permissions for current list
                    refreshObjectPermission(data.list, 'directory');
                    // Refresh permissions for its sub folders
                    angular.forEach(data.list.directories, function(item, key) {
                        refreshObjectPermission(item, 'directory').then(function() {
                            // Remove items when trueReadable = false
                            if (item.trueReadable === false) {
                                // data.list.directories.splice(key, 1);
                                delete data.list.directories[key];
                            }
                        });
                    });
                    // Refresh permissions for its files
                    angular.forEach(data.list.files, function(item, key) {
                        refreshObjectPermission(item, 'file').then(function() {
                            if (item.trueReadable === false) {
                                // data.list.files.splice(key, 1);
                                delete data.list.files.splice[key];
                            }
                        });
                    });
                    vm.data = data;
                    console.log(vm.data);
                }, function(result) {
                    vm.loadingEnd();
                    switch (result.status) {
                        case 403:
                            vm.breadcrumbs = [];
                            vm.notAllowed = true;
                            break;
                    }
                });
            }

            $scope.$on('refreshDiskDirectory', function(event, data) {
                vm.getSearchResult(path.keywords);
            });

            // Init
            if(path.keywords) {
                $scope.$emit('refreshDiskDirectory');
            } else {
                $location.path('/');
            }

        });
})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .controller('ShareController', function ($scope, $http, $timeout, $interval, $rootScope, $location, $q, apiShareService, authService, path) {
            $location.search({});
            var vm = this;
            vm.isLoading = false;
            vm.errorMsg = [];
            vm.list = [];
            vm.sharingLinkItem = [];
            vm.type = "";
            vm.link = path.link;

            vm.loadingStart = function() {
                $rootScope.$emit('loadingStart', 'disk');
                vm.errorMsg = [];
            };

            vm.loadingEnd = function() {
                $rootScope.$emit('loadingEnd', 'disk');
            };

            vm.renderBreadcrumb = function() {
                vm.breadcrumbs = [];
                var digDir = function(node) {
                    if(node) {
                        vm.breadcrumbs.unshift(node);
                    }
                    if (node && node.nested_parents) {
                        digDir(node.nested_parents);
                    }
                }
                digDir(vm.list[0].nested_parents);
            }

            vm.verifySharing = function(link) {
                var deferred = $q.defer();
                vm.loadingStart();
                apiShareService.verify(link).then(function(data) {
                    vm.loadingEnd();
                    vm.sharingLinkItem = data.item;
                    if(!data.success) {
                        deferred.reject(data.message);
                    } else {
                        deferred.resolve(data.item);
                    }
                }, function(err) {
                    vm.loadingEnd();
                    deferred.reject(err);
                });
                return deferred.promise;
            };


            vm.load = function(link, dirId) {
                vm.verifySharing(link).then(function(linkItem) {
                    if(linkItem.directory_id !== null) {
                        apiShareService.getDirectory(link, dirId).then(function(data) {
                            vm.list = data.list;
                            vm.type = 'directory';
                            vm.renderBreadcrumb();
                        });
                    } else {
                        apiShareService.getFile(link).then(function (data) {
                            vm.list = data.list;
                            vm.type = 'file';
                        });
                    }
                }, function(err) {
                    vm.errorMsg = err.data.message;
                });
            };

            // Init
            vm.load(path.link, path.dirId);

        });
})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .controller('WelcomeController', function ($scope, $http, $timeout, $interval) {

            var vm = this;

            var banners = ["computer", "home-appliance", "mobile", "tv-and-home-entertainment"];

            let randbanner = banners[Math.floor(Math.random() * banners.length)];
            $scope.bannerStyle= randbanner;


            $scope.getAppName = function() {
                return window.config.app_name;
            }

        });
})();

(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('carousel', [ function(){
            return {
                restrict: 'A',
                scope: {
                    'slides': '=carousel',
                },
                transclude: true,
                link: function(scope, element, attrs, ctrl, transclude) {
                    transclude(scope, function(clone){
                        element.append(clone);
                    });
                    scope.myInterval = 6000;
                    scope.noWrapSlides = false;
                    scope.active = 0;
                    scope.goTo = function(slide) {
                        if(!_.isUndefined(slide.url))
                        {
                            window.location.href = slide.url;
                        }
                    }
                }
            };
        }]);

})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('chars', ['$timeout', function ($timeout) {
            /* RegEx Examples:
                    - email: "0-9a-zA-Z@._\-"
                    - numbers only: "0-9"
                    - letters only: "a-zA-Z"
                    Email Usage Example:
                    <input type="text" name="email" ng-model="user.email" chars="[^0-9a-zA-Z@._\-]" />
                */
            'use strict';
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function ($scope, $elem, attrs, $ctrl) {
                    var regReplace,
                        preset = {
                            'only-numbers': '[^0-9]',
                            'numbers': '[^0-9\\s]',
                            'only-letters': '[^A-Za-z]',
                            'letters': '[^A-Za-z\\s]',
                            'email': '[^\\wÑñ@._\\-]',
                            'alpha-numeric': '[^\\w\\s]',
                            'alpha-numeric2': '[^\\w\\s$]',
                            'latin-alpha-numeric': '[^\\w\\sÑñáéíóúüÁÉÍÓÚÜ´¨]',
                            'folder': '[\\/\\\\\\<\\>\\:\\"\\\'\\|\\?\\*]',
                        },
                        filter = preset[attrs.chars] || attrs.chars;
                    $elem.on('input keyup change', function () {
                        var val = $elem.val().toString();
                        regReplace = new RegExp(filter, 'ig');
                        $ctrl.$setViewValue(val.replace(regReplace, ''));

                        if(/\s+$/.test(val)) {

                            //DO NOTHING, ALLOW SPACES

                        } else {
                            $timeout(function () {
                                $ctrl.$render();
                            });
                        }

                    });
                }
            };
        }]);

})();


(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('copyToClipboard', function() {
            return {
                restrict: 'A',
                scope: {
                    inputElement: '@'
                },
                link: function(scope, element, attrs) {
                    element.click(function () {
                        if (!attrs.inputElement) {
                            return;
                        }
                        var input = $(scope.inputElement);
                        input.select();
                        document.execCommand("copy");
                    });
                }
            }
        });


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('deleteDirectory', ['apiDiskService', 'apiShareService', 'commonModals', function(apiDiskService, apiShareService, commonModals) {
            return {
                restrict: 'A',
                scope: {
                    onObject: '=?',
                },
                link: function(scope, $elm, attrs) {
                    $elm.click(function() {
                        commonModals.confirmModal("Are you sure you want to permanently delete the folder: <strong>" + scope.onObject.name + "</strong>?").then(function() {
                            var id = scope.onObject.id;
                            var service;
                            scope.$emit('loadingStart', 'modal');
                            service = apiDiskService.deleteDirectory(id);
                            service.then(function (data) {
                                scope.$emit('loadingEnd', 'modal');
                                scope.$emit('refreshDiskDirectory');
                            }, function (result) {
                                console.log(result);
                                scope.$emit('loadingEnd', 'modal');
                                var message = 'Something went wrong.';
                                if(result.data.success === false && result.data.message.length) {
                                    message = result.data.message;
                                }
                                commonModals.alertModal(message, 'Error');
                            });
                        });

                    });
                }
            }
        }]);


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('deleteFile', ['apiDiskService', 'apiShareService', 'commonModals', function(apiDiskService, apiShareService, commonModals) {
            return {
                restrict: 'A',
                scope: {
                    onObject: '=?',
                },
                link: function(scope, $elm, attrs) {
                    $elm.click(function() {
                        commonModals.confirmModal("Are you sure you want to permanently delete the file: <strong>" + scope.onObject.name + "</strong>?").then(function() {
                            var id = scope.onObject.id;
                            var service;
                            scope.$emit('loadingStart', 'modal');
                            service = apiDiskService.deleteFile(id);
                            service.then(function (data) {
                                scope.$emit('loadingEnd', 'modal');
                                scope.$emit('refreshDiskDirectory');
                            }, function (result) {
                                console.log(result);
                                scope.$emit('loadingEnd', 'modal');
                                var message = 'Something went wrong.';
                                if(result.data.success === false && result.data.message.length) {
                                    message = result.data.message;
                                }
                                commonModals.alertModal(message, 'Error');
                            });
                        });

                    });
                }
            }
        }]);


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('diskRowDirectory', [ function(){
            return {
                restrict: 'A',
                templateUrl: 'common/directives/disk-row-directory.html',
                scope: {
                    list: '=',
                    item: '=',
                },
                transclude: true,
                link: function(scope, element, attrs, ctrl, transclude) {
                    transclude(scope, function(clone){
                        element.append(clone);
                    });
                }
            };
        }]);

})();
(function () {
    "use strict";

    angular.module("app.commonModule").directive("diskRowFile", [
        function () {
            return {
                restrict: "A",
                templateUrl: "common/directives/disk-row-file.html",
                scope: {
                    list: "=",
                    item: "=",
                },
                controller: function ($scope) {
                    $scope.onFileSelection = function () {
                        // Emit event with file id and selection state
                        $scope.$emit("updateSelectedFiles", { 
                            id: $scope.item.id, 
                            isSelected: $scope.item.isSelected 
                        });
                    };
                },
                transclude: true,
                link: function (scope, element, attrs, ctrl, transclude) {
                    transclude(scope, function (clone) {
                        element.append(clone);
                    });
                },
            };
        },
    ]);
})();

(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('diskRowHead', [ function(){
            return {
                restrict: 'A',
                templateUrl: 'common/directives/disk-row-head.html',
                scope: {
                },
                transclude: true,
                link: function(scope, element, attrs, ctrl, transclude) {
                    transclude(scope, function(clone){
                        element.append(clone);
                    });
                }
            };
        }]);

})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('diskTileDirectory', [ function(){
            return {
                restrict: 'A',
                templateUrl: 'common/directives/disk-tile-directory.html',
                scope: {
                    list: '=',
                    item: '=',
                },
                transclude: true,
                link: function(scope, element, attrs, ctrl, transclude) {
                    transclude(scope, function(clone){
                        element.append(clone);
                    });
                }
            };
        }]);

})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('downloadDirectory', ['apiDiskService', 'apiShareService', 'commonModals', function(apiDiskService, apiShareService, commonModals) {
            return {
                restrict: 'A',
                replace: false,
                scope: {
                    // triggerAction: '@',
                    directoryId: '=?',
                    sharingLink: '=?'
                },
                link: function(scope, $elm, attrs) {
                    var link = scope.sharingLink;
                    var id = scope.directoryId;
                    scope.trigger = function($event) {
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);
                        commonModals.openDirectoryDownloadModal(id, link).then(function(result){
                            angular.element('.modal-backdrop').remove();
                            // scope.$emit('refreshDiskDirectory');
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    };

                    /*if (scope.triggerAction == 'dblClick') {
                        // Double Click
                        $elm.dblclick(scope.trigger);
                    } else {*/
                        // Click
                        $elm.click(scope.trigger);
                    //}
                }
            }
        }]);


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('downloadFile', ['apiDiskService', 'apiShareService', 'commonModals', function(apiDiskService, apiShareService, commonModals) {
            return {
                restrict: 'A',
                scope: {
                    triggerAction: '@',
                    fileInfoId: '=?',
                    sharingLink: '=?'
                },
                link: function(scope, $elm, attrs) {

                    scope.trigger = function() {
                        commonModals.alertModal("Your download will begin shortly", "Download is in progress");
                        var link = scope.sharingLink;
                        var id = scope.fileInfoId;
                        var service;
                        if (link) {
                            // Share Service
                            service = apiShareService.downloadFile(id, link);
                        } else {
                            // Disk Service
                            service = apiDiskService.downloadFile(id);
                        }
                        service.then(function(data) {
                            // Start Downloading
                            window.location = data.url;
                            // scope.$apply();
                        }, function(result) {
                            console.log(result);
                        });
                    };


                    if (scope.triggerAction == 'dblClick') {
                        // Double Click
                        $elm.dblclick(scope.trigger);
                    } else {
                        // Click
                        $elm.click(scope.trigger);
                    }
                }
            }
        }]);


})();
(function () {
    "use strict";

    angular.module("app.commonModule").directive("downloadMultipleFile", [
        "apiDiskService",
        "apiShareService",
        "commonModals",
        function (apiDiskService, apiShareService,commonModals) {
            return {
                restrict: "A",
                scope: {
                    triggerAction: "@",
                    fileids: "=", // Pass an array of file IDs
                    sharingLink: '=?'
                },
                link: function (scope, $elm, attrs) {
                    scope.trigger = function () {
                        const values = Object.keys(scope.fileids);
                        if (!Array.isArray(values) || values.length === 0) {
                            // commonModals.alertModal(
                            //     "No files selected",
                            //     "Please select files to download."
                            // );
                            return;
                        }

                        commonModals.alertModal(
                            "Your download will begin shortly",
                            "Download is in progress"
                        );

                        // Call the API to download multiple files
                        apiDiskService.downloadFiles(values).then(
                            function (data) {
                                if (data && data.url) {
                                    window.location = data.url; // Trigger ZIP download
                                }
                            },
                            function (error) {
                                console.error(
                                    "Error downloading files:",
                                    error
                                );
                            }
                        );
                    };

                    // Trigger the download on click
                    $elm.click(scope.trigger);
                },
            };
        },
    ]);
})();

(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('jumpToDirectory', ['$location', function($location) {
            return {
                restrict: 'A',
                scope: {
                    dirId: '=?',
                    dirName: '=?'
                },
                link: function(scope, $elm, attrs) {
                    $elm.click(function() {
                        var dirName = scope.dirName || '';
                        dirName = dirName.replace(/\s+/g, '-').toLowerCase();
                        if(scope.dirId === undefined) {
                            $location.path('/');
                        } else {
                            $location.path('/' + scope.dirId + '/' + dirName);
                        }
                        scope.$apply();
                    });
                }
            }
        }]);


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('jumpToShareDirectory', ['$location', function($location) {
            return {
                restrict: 'A',
                scope: {
                    dirId: '=?',
                    dirName: '=?',
                    sharingLink: '=',
                },
                link: function(scope, $elm, attrs) {
                    $elm.click(function() {
                        var dirName = scope.dirName || '';
                        dirName = dirName.replace(/\s+/g, '-').toLowerCase();
                        if(scope.dirId === undefined) {
                            $location.path('/s/' + scope.sharingLink);
                        } else {
                            $location.path('/s/' + scope.sharingLink + '/' + scope.dirId + '/' + dirName);
                        }
                        scope.$apply();
                    });
                }
            }
        }]);


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('moveDirectoryList', ['$compile', function($compile) {
            return {
                transclude: false,
                restrict: 'E',
                scope: {
                    items: '=',
                    moving: '@'
                },
                link: function(scope, $elm, attrs) {
                    scope.open = false;
                    scope.$watch('items', function (newValue, oldValue) {
                        if (scope.items) {
                            $elm.empty();
                            for(var i = 0; i < scope.items.length; i++) {
                                if (scope.items[i].id == scope.moving) {
                                    continue;
                                }
                                var build = function(i) {
                                    var div = angular.element('<li></li>');

                                    var span = angular.element('<span></span>');
                                    var link = angular.element('<a href="javascript:;">' + scope.items[i].name + '</a>');
                                    span.append(link);

                                    if (scope.items[i].nested_children_short && scope.items[i].nested_children_short.length > 0) {
                                        var toggle = angular.element('<a href="javascript:;" class="toggle">+</a>');
                                        span.append(toggle);
                                        toggle.click(function($event) {
                                            $event.preventDefault();
                                            scope.open = !scope.open;
                                            if (scope.open) {
                                                toggle.text('-');
                                                div.find(' > > move-directory-list').removeClass('hide');
                                                div.find(' > > move-directory-list').addClass('show');
                                            } else {
                                                toggle.text('+');
                                                div.find(' > > move-directory-list').removeClass('show');
                                                div.find(' > > move-directory-list').addClass('hide');
                                            }
                                        });
                                    }
                                    link.click(function($event) {
                                        $event.preventDefault();
                                        angular.element('.directory-selected').removeClass('directory-selected');
                                        link.addClass('directory-selected');
                                        scope.$emit('moveDirectory', scope.items[i]);
                                    });
                                    div.append(span);

                                    // Create a new child scope for the nested directive
                                    var childScope = scope.$new();
                                    childScope.nestedItems = scope.items[i].nested_children_short;

                                    // Use the child scope and pass nestedItems to items attribute
                                    var nestedDirective = angular.element('<move-directory-list items="nestedItems" moving="' + scope.moving + '"></move-directory-list>');
                                    if (scope.open) {
                                        nestedDirective.addClass('show');
                                    } else {
                                        nestedDirective.addClass('hide');
                                    }
                                    var ul = angular.element('<ul></ul>');
                                    ul.append(nestedDirective);
                                    div.append(ul);

                                    $compile(div)(childScope);  // Compile with the new child scope
                                    $elm.append(div);
                                }(i);
                            }
                        }
                    });
                }
            }
        }]);

})();

(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('moveDirectory', ['apiDiskService', 'apiShareService', 'commonModals', function(apiDiskService, apiShareService, commonModals) {
            return {
                restrict: 'A',
                scope: {
                    onObject: '=',
                    moveId: '=',
                    type: '@'
                },
                link: function(scope, $elm, attrs) {
                    $elm.click(function($event) {
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openMoveDirectoryModal(scope.onObject, scope.moveId, scope.type).then(function(result){
                            angular.element('.modal-backdrop').remove();
                            scope.$emit('refreshDiskDirectory');
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            }
        }]);

})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('newDirectory', ['commonModals', function(commonModals){
            return {
                restrict: 'A',
                replace: false,
                scope: {
                    onObject: '='
                },
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        console.log(scope.onObject);
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openNewDirectoryModal(scope.onObject).then(function(result){
                            angular.element('.modal-backdrop').remove();
                            scope.$emit('refreshDiskDirectory');
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            };
        }]);
})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('openSubscribeToMailchimpDialog', ['commonModals', function(commonModals){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openSubscribeModal().then(function(result){
                            window.location.reload();
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            };
        }]);
})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('permissionDirectory', ['apiDiskService', 'apiShareService', 'commonModals', function(apiDiskService, apiShareService, commonModals) {
            return {
                restrict: 'A',
                scope: {
                    onObject: '='
                },
                link: function(scope, $elm, attrs) {
                    $elm.click(function($event) {
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openPermissionModal('directory', scope.onObject).then(function(result){
                            angular.element('.modal-backdrop').remove();
                            scope.$emit('refreshDiskDirectory');
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            }
        }]);

})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('permissionFile', ['apiDiskService', 'apiShareService', 'commonModals', function(apiDiskService, apiShareService, commonModals) {
            return {
                restrict: 'A',
                scope: {
                    onObject: '='
                },
                link: function(scope, $elm, attrs) {
                    $elm.click(function($event) {
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openPermissionModal('file', scope.onObject).then(function(result){
                            angular.element('.modal-backdrop').remove();
                            scope.$emit('refreshDiskDirectory');
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            }
        }]);
    
})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('previewFile', ['commonModals', function(commonModals) {
            return {
                restrict: 'A',
                scope: {
                    onObject: '='
                },
                link: function(scope, $elm, attrs) {

                    $elm.click(function($event){
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openPreviewModal('file', scope.onObject).then(function(result){
                            angular.element('.modal-backdrop').remove();
                            scope.$emit('refreshDiskDirectory');
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            }
        }]);


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('renameDirectory', ['apiDiskService', 'apiShareService', 'commonModals', function(apiDiskService, apiShareService, commonModals) {
            return {
                restrict: 'A',
                scope: {
                    onObject: '='
                },
                link: function(scope, $elm, attrs) {
                    $elm.click(function($event) {
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openRenameModal('directory', scope.onObject).then(function(result){
                            angular.element('.modal-backdrop').remove();
                            scope.$emit('refreshDiskDirectory');
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            }
        }]);


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('renameFile', ['apiDiskService', 'apiShareService', 'commonModals', function(apiDiskService, apiShareService, commonModals) {
            return {
                restrict: 'A',
                scope: {
                    onObject: '='
                },
                link: function(scope, $elm, attrs) {
                    $elm.click(function($event){
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openRenameModal('file', scope.onObject).then(function(result){
                            angular.element('.modal-backdrop').remove();
                            scope.$emit('refreshDiskDirectory');
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            }
        }]);


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('scrollTo', function() {
            return {
                restrict: 'A',
                link: function(scope, $elm, attrs) {
                    var idToScroll = attrs.href;
                    var offset = parseInt(attrs.offset) || 0;
                    $elm.on('click', function() {
                        var $target;
                        if (idToScroll) {
                            $target = $(idToScroll);
                        } else {
                            $target = $elm;
                        }
                        console.log('scrollTo: $target.offset().top', $target.offset().top);
                        var body = $("html, body");
                        body.stop().animate({scrollTop: $target.offset().top + offset}, 500, 'swing', function() {
                            console.log("Finished scrollTo " + $target.offset().top + offset);
                        });
                    });
                }
            }
        });


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('shareObject', ['commonModals', function(commonModals){
            return {
                restrict: 'A',
                replace: false,
                scope: {
                    objectType: '@',
                    shareObject: '='
                },
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        console.log(scope.shareObject);
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openShareModal(scope.objectType, scope.shareObject).then(function(result){
                            angular.element('.modal-backdrop').remove();
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            };
        }]);
})();

(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('shareRowDirectory', [ function(){
            return {
                restrict: 'A',
                templateUrl: 'common/directives/share-row-directory.html',
                scope: {
                    item: '=',
                    sharingLink: "="
                },
                transclude: true,
                link: function(scope, element, attrs, ctrl, transclude) {
                    transclude(scope, function(clone){
                        element.append(clone);
                    });
                }
            };
        }]);

})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('shareRowFile', [ function(){
            return {
                restrict: 'A',
                templateUrl: 'common/directives/share-row-file.html',
                scope: {
                    item: '=',
                    sharingLink: "="
                },
                transclude: true,
                link: function(scope, element, attrs, ctrl, transclude) {
                    transclude(scope, function(clone){
                        element.append(clone);
                    });
                }
            };
        }]);

})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('shareRowHead', [ function(){
            return {
                restrict: 'A',
                templateUrl: 'common/directives/share-row-head.html',
                scope: {
                },
                transclude: true,
                link: function(scope, element, attrs, ctrl, transclude) {
                    transclude(scope, function(clone){
                        element.append(clone);
                    });
                }
            };
        }]);

})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('showIfCanMove', function() {
            return {
                restrict: 'A',
                scope: {
                    showIfCanMove: '='
                },
                link: function(scope, $elm, attrs) {
                    if(scope.showIfCanMove) {
                        $elm.removeClass('hidden');
                        $elm.show();
                    }
                }
            }
        });


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('showIfHasPermission', function() {
            return {
                restrict: 'A',
                scope: {
                    showIfHasPermission: '@'
                },
                link: function(scope, $elm, attrs) {
                    if(window.config.user_permissions.indexOf(scope.showIfHasPermission) !== -1) {
                        $elm.removeClass('hidden');
                        $elm.show();
                    }
                }
            }
        });


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('showIfIsAdmin', function() {
            return {
                restrict: 'A',
                link: function(scope, $elm, attrs) {
                    if(window.config.user_roles.indexOf('admin') !== -1) {
                        $elm.removeClass('hidden');
                        $elm.show();
                    }
                }
            }
        });


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('showIfIsOwner', function() {
            return {
                restrict: 'A',
                scope: {
                    userId: '=showIfIsOwner'
                },
                link: function(scope, $elm, attrs) {
                    if(window.config.user_id == scope.userId) {
                        console.log('Is Object Owner');
                        $elm.removeClass('hidden');
                        $elm.show();
                    }
                }
            }
        });


})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .directive('tagObject', ['commonModals', function(commonModals){
            return {
                restrict: 'A',
                replace: false,
                scope: {
                    objectType: '@',
                    tagObject: '='
                },
                link: function(scope, element, attrs, ctrl) {
                    element.on('click', function($event){
                        console.log(scope.tagObject);
                        $event.preventDefault();
                        angular.element('<div class="modal-backdrop fade in"></div>').appendTo(document.body);

                        commonModals.openTagModal(scope.objectType, scope.tagObject).then(function(result){
                            angular.element('.modal-backdrop').remove();
                            scope.$emit('refreshDiskDirectory');
                        }).catch(function(err){
                            angular.element('.modal-backdrop').remove();
                        });
                    });
                }
            };
        }]);
})();

(function () {
    'use strict';

    angular.module('app.commonModule')
        .filter('contains', function() {
            return function (array, needle) {
                return array.indexOf(needle) >= 0;
            };
        });
})();

(function () {
    'use strict';
    angular.module('app.commonModule')
        .filter('filesize', function () {
            return function (bytes, precision) {
                if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes === 0) return '';
                if (typeof precision === 'undefined') precision = 1;
                var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'],
                    number = Math.floor(Math.log(bytes) / Math.log(1024));
                return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
            }
        });
})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .filter('nl2br', function() {
            return function(input) {
                if (input !== void 0) {
                    return input.replace(/\n/g, '<br>');
                }
            };
        });

})();
(function () {
    'use strict';

    angular.module('app.commonModule')
        .filter('objLength', function() {
            return function(object) {
                return Object.keys(object).length;
            }
        })

})();
(function () {
    'use strict';
    angular.module('app.commonModule')
        .filter('slug', function () {
            return function (input) {
                if (!input)
                    return;

                // make lower case and trim
                var slug = input.toLowerCase().trim();

                // replace invalid chars with spaces
                slug = slug.replace(/[^a-z0-9\s-]/g, '');

                // replace multiple spaces or hyphens with a single hyphen
                slug = slug.replace(/[\s-]+/g, '-');

                return slug;
            }
        });
})();
(function () {
'use strict';

angular.module('app.commonModule').factory('commonModals', ['$filter', '$uibModal', '$rootScope', '$q', 'apiShareService', 'apiDiskService', 'apiTagService', 'apiGroupService', '$location', commonModals]);
    function commonModals ($filter, $uibModal, $rootScope, $q, apiShareService, apiDiskService, apiTagService, apiGroupService, $location){

        var service = {
            setCookie: function(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays*24*60*60*1000));
                var expires = "expires="+ d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },
            getCookie: function(cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for(var i = 0; i <ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            openShareModal: function(shareType, shareObject){
                var deferred = $q.defer();
                var modal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal',
                    templateUrl: 'common/directives/share-object.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: false,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.isLoading = false;
                        modalCtrl.showSettings = false;
                        modalCtrl.settings = angular.copy(shareObject.sharing_link);
                        modalCtrl.shareObject = shareObject;
                        modalCtrl.datePickerConfig = {
                            'minDate': new Date(), //(new Date()).setDate((new Date().getDate())+1)
                        };
                        modalCtrl.getAbsSharingLink = function() {
                            var link = modalCtrl.shareObject.sharing_link.link;
                            return $location.protocol() + "://" + $location.host() + "/s/" + link;
                        }
                        modalCtrl.startSharing = function() {
                            this.isLoading = true;
                            apiShareService.startSharing({
                                'type': shareType,
                                'id': shareObject.id
                            }).then(function(data){
                                modalCtrl.shareObject.sharing_link = data;
                                modalCtrl.isLoading = false;
                                service.alertModal("Now you can adjust the link expiry and copy the link.", 'A new link has been created successfully');
                            }, function(err){
                                modalCtrl.isLoading = false;
                                service.alertModal(err.data.message);
                                modalCtrl.shareObject.sharing_link = {};
                            });
                        }
                        // FORCE CREATE NEW
                        modalCtrl.startSharing();


                        /*modalCtrl.stopSharing = function() {
                            service.confirmModal("Are you sure to remove the sharing link?").then(function(){
                                apiShareService.stopSharing(modalCtrl.shareObject.sharing_link.link).then(function(){
                                    modalCtrl.shareObject.sharing_link = null;
                                    service.alertModal("The sharing link has been removed and no longer valid.", "Sharing Link Removed");
                                    $scope.$close();
                                }, function(err){
                                });
                            })
                        }*/
                        modalCtrl.saveSettings = function() {
                            if ((typeof modalCtrl.settings.expiry_at == "undefined")
                                || !(modalCtrl.settings.expiry_at instanceof Date)) {
                                modalCtrl.showSettings = false;
                                return;
                            }
                            var params = {
                                'expiry_at': modalCtrl.settings.expiry_at.getDate() + '/' + (modalCtrl.settings.expiry_at.getMonth()+1) + '/' + modalCtrl.settings.expiry_at.getFullYear()
                            };
                            apiShareService.saveSettings(modalCtrl.shareObject.sharing_link.link, params).then(function(data){
                                modalCtrl.shareObject.sharing_link = data;
                                // service.alertModal("Setting Saved");
                                modalCtrl.showSettings = false;
                                console.log(modalCtrl.shareObject);
                            }, function(err){
                                service.alertModal(err.data.message);
                            });
                        }
                    }
                });

                modal.result.then(function(result){
                    console.log('onHide arguments close', arguments);
                    deferred.resolve(result);
                }, function(){
                    console.log('onHide arguments dismiss', arguments);
                    deferred.reject();
                });

                return deferred.promise;

            },
            openTagModal: function(objectType, tagObject){
                var deferred = $q.defer();
                var modal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal',
                    templateUrl: 'common/directives/tag-object.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: false,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.isLoading = false;
                        modalCtrl.tagObject = tagObject;
                        modalCtrl.objectType = objectType;
                        modalCtrl.dataList = [];

                        modalCtrl.mainTags = [];
                        modalCtrl.selectedMainTags = [];
                        modalCtrl.editingMainTags = true;

                        modalCtrl.subTags = [];
                        modalCtrl.selectedSubTags = [];

                        apiTagService.getAll().then(function(data){
                            modalCtrl.dataList = data.list;
                            for(var key in modalCtrl.dataList) {
                                modalCtrl.mainTags.push({
                                    'label': modalCtrl.dataList[key]['name'],
                                    'value': modalCtrl.dataList[key]['id'],
                                    });
                            };
                        }, function(err){
                            service.alertModal(err.data.message);
                        })
                        .then(function(){
                            // Render existing main tags
                            modalCtrl.selectedMainTags = modalCtrl.tagObject.tags.filter(tag => {
                                var display = false;
                                modalCtrl.dataList.forEach(t => {
                                    if (t.id === tag.id) {
                                        display = true;
                                        return true;
                                    }
                                });
                                return display;
                            }).map(tag => tag.id);
                            // Render existing sub tags
                            if (modalCtrl.selectedMainTags.length > 0) {
                                modalCtrl.selectedSubTags = modalCtrl.tagObject.tags
                                    .filter(tag => modalCtrl.selectedMainTags.indexOf(tag.id) < 0)
                                    .map(tag => tag.id);
                                modalCtrl.changeMainTags();
                            }
                        }, function(err){
                            service.alertModal(err.data.message);
                        });

                        modalCtrl.changeMainTags = function() {
                            modalCtrl.subTags = [];
                            for(var key in modalCtrl.dataList) {
                                if( modalCtrl.selectedMainTags.indexOf(modalCtrl.dataList[key]['id']) >= 0 ) {
                                    var newList = modalCtrl.dataList[key]['children'];
                                    if(newList.length <= 0) {
                                        continue;
                                    }
                                    for(var k in newList) {
                                        modalCtrl.subTags.push({
                                            'label': newList[k]['name'],
                                            'value': newList[k]['id'],
                                        });
                                    }
                                }
                            }

                            // Remove selected sub tags whose parent not being selected
                            modalCtrl.selectedSubTags = modalCtrl.selectedSubTags.filter(tag => {
                                var keep = false;
                                modalCtrl.subTags.forEach(t => {
                                    if(t.value == tag) {
                                        keep = true;
                                        return true;
                                    }
                                });
                                return keep;
                            });

                            modalCtrl.editingMainTags = false;
                        };

                        modalCtrl.save = function() {
                            var selectedTags = modalCtrl.selectedMainTags.concat(modalCtrl.selectedSubTags).filter(el => el != null);
                            apiTagService.updateObjectTags(modalCtrl.tagObject['id'], objectType, selectedTags).then(function(data){
                                $scope.$close();
                            }, function(err){
                                service.alertModal(err.data.message);
                            });
                        }
                    }
                });

                modal.result.then(function(result){
                    console.log('onHide arguments close', arguments);
                    deferred.resolve(result);
                }, function(){
                    console.log('onHide arguments dismiss', arguments);
                    deferred.reject();
                });

                return deferred.promise;

            },
            openNewDirectoryModal: function(onObject){
                var deferred = $q.defer();
                var modal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal',
                    templateUrl: 'common/directives/new-directory.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: false,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.isLoading = false;
                        modalCtrl.onObject = onObject;
                        modalCtrl.name = "";
                        modalCtrl.create = function() {
                            if (!modalCtrl.name) {
                                service.alertModal("Directory name cannot be empty", 'Error');
                                return;
                            }
                            if (modalCtrl.name === '.' || modalCtrl.name === '..') {
                                service.alertModal("Directory name cannot be \".\" or \"..\"", 'Error');
                                return;
                            }
                            apiDiskService.newDirectory(modalCtrl.onObject.id, modalCtrl.name).then(function(){
                                $scope.$close();
                            }, function(err){
                                var message = 'Something went wrong.';
                                if(err.data.success === false && err.data.message.length) {
                                    message = err.data.message;
                                }
                                service.alertModal(message, 'Error');
                            });
                        }
                    }
                });

                modal.result.then(function(result){
                    console.log('onHide arguments close', arguments);
                    deferred.resolve(result);
                }, function(){
                    console.log('onHide arguments dismiss', arguments);
                    deferred.reject();
                });

                return deferred.promise;

            },
            openRenameModal: function(objectType, onObject){
                var deferred = $q.defer();
                var modal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal',
                    templateUrl: 'common/directives/rename.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: false,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.isLoading = false;
                        modalCtrl.onObject = onObject;
                        modalCtrl.name = onObject.name || "";
                        modalCtrl.rename = function() {
                            if (!modalCtrl.name) {
                                service.alertModal("Name cannot be empty", 'Error');
                                return;
                            }
                            if (modalCtrl.name === '.' || modalCtrl.name === '..') {
                                service.alertModal("Name cannot be \".\" or \"..\"", 'Error');
                                return;
                            }
                            apiDiskService.renameObject(objectType, modalCtrl.onObject.id, modalCtrl.name).then(function(){
                                $scope.$close();
                            }, function(err){
                                var message = 'Something went wrong.';
                                if(err.data.success === false && err.data.message.length) {
                                    message = err.data.message;
                                }
                                service.alertModal(message, 'Error');
                            });
                        }
                    }
                });

                modal.result.then(function(result){
                    console.log('onHide arguments close', arguments);
                    deferred.resolve(result);
                }, function(){
                    console.log('onHide arguments dismiss', arguments);
                    deferred.reject();
                });
                return deferred.promise;
            },
            openPreviewModal: function(objectType, onObject){
                var deferred = $q.defer();
                var modal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal full',
                    templateUrl: 'common/directives/preview.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: false,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.isLoading = false;
                        modalCtrl.onObject = onObject;
                        modalCtrl.name = onObject.name || "";
                    }
                });

                modal.result.then(function(result){
                    console.log('onHide arguments close', arguments);
                    deferred.resolve(result);
                }, function(){
                    console.log('onHide arguments dismiss', arguments);
                    deferred.reject();
                });
                return deferred.promise;
            },
            openDirectoryDownloadModal: function(id, link) {
                var deferred = $q.defer();
                var modal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal',
                    templateUrl: 'common/directives/download-directory.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: false,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.isLoading = true;

                        var service;
                        /*if (link) {
                            // Share Service
                            service = apiShareService.downloadDirectory(id, link);
                        } else {*/
                            // Disk Service
                            service = apiDiskService.prepareDownloadingDirectory(id);
                        // }

                        var interval = setInterval(function() {
                            service = apiDiskService.downloadDirectory(id);
                            service.then(function(data) {
                                // Start Downloading
                                clearInterval(interval);
                                $scope.$close();
                                window.location = data.url;
                                // scope.$apply();
                            }, function(result) {
                                console.log(result);
                            });
                        }, 2000);


                    }
                });

                modal.result.then(function(result){
                    console.log('onHide arguments close', arguments);
                    deferred.resolve(result);
                }, function(){
                    console.log('onHide arguments dismiss', arguments);
                    deferred.reject();
                });
                return deferred.promise;
            },
            openMoveDirectoryModal: function(onObject, moveId, type){
                var deferred = $q.defer();
                var parentPath = function (string, item) {
                    if (item) {
                        string = parentPath(item.name + ' / ' + string, item.nested_parents);
                    }
                    return string;
                }
                var parentPathShort = function (string, item) {
                    if (item) {
                        string = parentPath(item.name + ' / ' + string, item.nested_parents_short);
                    }
                    return string;
                }
                var modal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal',
                    templateUrl: 'common/directives/move.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: false,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.fullPath = parentPath(onObject.name, onObject.nested_parents);
                        modalCtrl.isLoading = false;
                        modalCtrl.enabled = 0;
                        modalCtrl.onObject = onObject;
                        modalCtrl.moveTo = null;
                        modalCtrl.moveToName = '';
                        modalCtrl.moveToFullPath = '';
                        apiDiskService.getMoveDirectories(moveId).then(function(data){
                            modalCtrl.directories = data.list;
                            modalCtrl.enabled = (modalCtrl.directories.length > 0);
                        }, function(err){
                            var message = 'Failed to get directories.';
                            if(err.data.success === false && err.data.message.length) {
                                message = err.data.message;
                            }
                            service.alertModal(message, 'Error');
                        });
                        $rootScope.$on('moveDirectory', function(on, data) {
                            $scope.$apply(function() {
                                modalCtrl.moveToFullPath = parentPathShort(data.name, data.nested_parents_short);
                                modalCtrl.moveTo = data.id;
                            });
                        })
                        modalCtrl.submit = function() {
                            if (type == 'directory') {
                                apiDiskService.moveDirectory(modalCtrl.onObject.id, modalCtrl.moveTo).then(function(){
                                    $scope.$close();
                                });
                            } else if (type == 'file') {
                                apiDiskService.moveFile(modalCtrl.onObject.id, modalCtrl.moveTo).then(function(){
                                    $scope.$close();
                                });
                            }
                        }
                    }
                });

                modal.result.then(function(result){
                    console.log('onHide arguments close', arguments);
                    deferred.resolve(result);
                }, function(){
                    console.log('onHide arguments dismiss', arguments);
                    deferred.reject();
                });
                return deferred.promise;
            },
            openPermissionModal: function(objectType, onObject){
                var deferred = $q.defer();
                var modal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal',
                    templateUrl: 'common/directives/permission.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: false,
                    animation: true,
                    controller: function($scope){
                        var modalCtrl = this;
                        modalCtrl.isLoading = false;
                        modalCtrl.enabled = 0;
                        modalCtrl.onObject = onObject;
                        modalCtrl.name = onObject.name || "";
                        modalCtrl.groups = [];

                        modalCtrl.permissionTypes = [];
                        modalCtrl.permissionTypes.push({
                            'label': "Inherit Parent",
                            'value': null,
                        });
                        modalCtrl.permissionTypes.push({
                            'label': "No Access",
                            'value': "0.0",
                        });
                        modalCtrl.permissionTypes.push({
                            'label': "Read-only",
                            'value': "1.0",
                        });
                        modalCtrl.permissionTypes.push({
                            'label': "Read & Write",
                            'value': "1.1",
                        });

                        // { group_id|0 : R.W }
                        modalCtrl.pickedPermission = [];
                        if(modalCtrl.onObject.readable !== null) {
                            modalCtrl.pickedPermission[0] = modalCtrl.onObject.readable + '.' + modalCtrl.onObject.writable; // Everyone permission
                        }
                        apiGroupService.getGroups().then(function(data){
                            modalCtrl.groups = data.list;
                            modalCtrl.enabled = (modalCtrl.onObject.groups.length > 0 || modalCtrl.onObject.readable !== null);
                            for (var i in modalCtrl.onObject.groups) {
                                if(modalCtrl.onObject.groups[i].pivot.readable !== null) {
                                    modalCtrl.pickedPermission[modalCtrl.onObject.groups[i].pivot.group_id] = modalCtrl.onObject.groups[i].pivot.readable + '.' + modalCtrl.onObject.groups[i].pivot.writable;
                                }
                            }
                        }, function(err){
                            var message = 'Failed to get groups.';
                            if(err.data.success === false && err.data.message.length) {
                                message = err.data.message;
                            }
                            service.alertModal(message, 'Error');
                        });

                        modalCtrl.submit = function() {
                            if (!modalCtrl.enabled) {
                                modalCtrl.pickedPermission = [];
                            }
                            apiDiskService.updateObjectPermission(objectType, modalCtrl.onObject.id, modalCtrl.pickedPermission).then(function(){
                                $scope.$close();
                            }, function(err){
                                var message = 'Something went wrong.';
                                if(err.data.success === false && err.data.message.length) {
                                    message = err.data.message;
                                }
                                service.alertModal(message, 'Error');
                            });
                        }
                    }
                });

                modal.result.then(function(result){
                    console.log('onHide arguments close', arguments);
                    deferred.resolve(result);
                }, function(){
                    console.log('onHide arguments dismiss', arguments);
                    deferred.reject();
                });
                return deferred.promise;
            },
            openPopupModal: function(){
                var popupModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal popupModal',
                    templateUrl: 'config/directives/popup.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                    }
                });

                return popupModal.result;
            },
            confirmModal: function(message){
                var confirmModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal confirm',
                    templateUrl: 'config/directives/confirm.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                        var _self = this;

                        _self.message = message;
                    }
                });

                return confirmModal.result;
            },
            alertModal: function(message, title){
                var deferred = $q.defer();
                title = title || '';

                var alertModal = $uibModal.open({
                    scope: $rootScope.$new(),
                    windowClass: 'modal alert',
                    templateUrl: 'config/directives/alert.modal.html',
                    controllerAs: 'modalCtrl',
                    backdrop: true,
                    animation: true,
                    controller: function($scope){
                        var _self = this;

                        _self.message = message;
                        _self.title = title;
                    }
                });

                // ensures that promise resolves regardless of how the user closes the modal
                alertModal.result.then(function(){
                    deferred.resolve();
                    console.log('closing child modal');
                }, function(){
                    console.log('closing child modal');
                    deferred.resolve();
                });

                return deferred.promise;
            },
        };

        return service;
    }
})();

(function () {
    'use strict';

    var app = angular.module('app.configModule', ['ngRoute']);

    app.config(['$interpolateProvider', function($interpolateProvider){
            // change the angular template variable symbols so it doesn't conflict with blade
            $interpolateProvider.startSymbol("{[{");
            $interpolateProvider.endSymbol("}]}");

            // ensure we can change the URL without reloading the page, and without the "hashtag" in the url
            /*
             $routeProvider.otherwise({
             reloadOnSearch: false
             });

             $locationProvider.html5Mode(true);
             */
    }]);

    app.config(['$routeProvider', function($routeProvider){
        $routeProvider.
        when('/login', {
            templateUrl: '/views/welcome.html',
            controller: 'WelcomeController',
            resolve: {
                loggedIn: ["authService", "$location", function(authService, $location) {
                    if (authService.loggedIn()) {
                        $location.path("/");
                    }
                }]
            }
        }).
        when('/search', {
            templateUrl: 'views/search.html',
            controller: 'SearchController',
            controllerAs: 'ctrl',
            resolve: {
                path: function($route) {
                    return $route.current.params;
                }
            },
            requireLogin: true
        }).
        when('/s/:link/:dirId?/:dirName?', {
            templateUrl: 'views/share.html',
            controller: 'ShareController',
            controllerAs: 'ctrl',
            resolve: {
                path: function($route) {
                    return $route.current.params;
                }
            },
            requireLogin: false
        }).
        when('/:dirId?/:dirName?', {
            templateUrl: 'views/disk.html',
            controller: 'DiskController',
            controllerAs: 'ctrl',
            resolve: {
                path: function($route) {
                    return {
                        dirId: $route.current.params.dirId
                    };
                }
            },
            requireLogin: true
        }).
        otherwise({
            //redirectTo: '/404'
            templateUrl: '/views/404.html'
        });
    }]);

    // http provider config
    /*app.config(['$httpProvider', function ($httpProvider) {
        $httpProvider.defaults.withCredentials = true;
        $httpProvider.interceptors.push(['$rootScope', '$location', function($rootScope, $location) {
            return {
                'request': function(config) {
                    $rootScope.$emit('loadingStart', config.url);
                    return config;
                },

                'response': function(response) {
                    $rootScope.$emit('loadingEnd', response.config.url);
                    return response;
                },

                // optional method
                'responseError': function(rejection) {
                    if (rejection && rejection.status) {
                        switch(rejection.status) {
                            case 401: // Bearer expired or user logged in on another device
                                window.config.api_token = '';
                                window.location = '/';
                                break;
                        }
                    } else if (typeof(rejection) == 'string') {
                        $rootScope.$emit('error', {'message': rejection});
                    }
                    if (rejection && rejection.config) {
                        $rootScope.$emit('loadingEnd', rejection.config.url);
                        $rootScope.$emit('error', {'id': 'config.$httpProvider.responseError','message': 'An error has occurred: ' + rejection.status + ' ' + rejection.statusText, 'code': rejection.status, 'data': rejection});
                    }
                    return rejection;
                }
            };
        }]);
    }]);*/


    // AngularJS 1.6 has changed the default for hash-bang urls in the $location service.
    // to revert to previous behaviour
    app.config(['$locationProvider', function($locationProvider) {
        $locationProvider.hashPrefix('');
        /*$locationProvider.html5Mode({
           enabled: true,
           requireBase: false,
           rewriteLinks: false
        });*/
    }]);

    // “Possibly unhandled rejection” error by Angular 1.6.0
    app.config(['$qProvider', function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    }]);

    app.run(['$rootScope', '$location', 'authService', function($rootScope, $location, authService){
        // check on routes whether the user has permission to view this url or not . And if not , then redirect to login page.
        $rootScope.$on('$routeChangeStart', function (event, nextRoute, current) {
            // check if the nextRoute requires the login or not . If login is required and user is not logged in then it is redirected to             login page.
            //if ((nextRoute && nextRoute.requireLogin) && !$rootScope.isLoggedIn) {
            if ((nextRoute && nextRoute.requireLogin) && !authService.loggedIn()) {
                $location.path("/login");
            } else {
                authService.initCurrentUser()
            }
        });
    }]);
})();

angular.module('app.configModule')
    .controller('ErrorController', ['$scope', '$rootScope', '$timeout', function ($scope, $rootScope, $timeout) {

    $scope.latest_error = [];

    $scope.triggerError = function(event, data) {
        $scope.addError(data);
    };

    $scope.addError = function(data) {
        $scope.latest_error.push(data);
        $timeout(function() {
            $scope.latest_error.shift();
        }, 6000);
    };

    $scope.close = function() {
        $scope.latest_error = [];
    };


    $rootScope.$on('error', function(event, data){
        $scope.triggerError(event, data);
    });

}]);
angular.module('app.configModule')
    .controller('LoadingController', ['$scope', '$rootScope', function ($scope, $rootScope) {

    $scope.loading = false;
    $scope.items = {};

    $rootScope.$on('loadingStart', function(on, data) {
        $rootScope.clearAutoReload();
        if (!$scope.items[data]) {
            $scope.items[data] = 1;
        } else {
            $scope.items[data]++;
        }
        $scope.checkLoading();
    });

    $rootScope.$on('loadingEnd', function(on, data) {
        if ($scope.items[data]) {
            $scope.items[data]--;
            if ($scope.items[data] === 0) {
                delete $scope.items[data];
            }
        }
        $scope.checkLoading();
    });

    $rootScope.clearAutoReload = () => {
        if($rootScope.autoReloadTimer) {
            clearTimeout($rootScope.autoReloadTimer);
        }
    };

    $scope.checkLoading = function() {
        $scope.loading = (Object.keys($scope.items).length > 0);
    };

}]);
(function () {
    'use strict';

    angular.module('app.configModule')
        .directive('ngPlaceholder', ['$document', function($document) {
        return {
            restrict: 'A',
            scope: {
                placeholder: '=ngPlaceholder'
            },
            link: function(scope, elem, attr) {
                scope.$watch('placeholder',function() {
                    elem[0].placeholder = scope.placeholder;
                });
            }
        }
    }]);

})();
(function () {
    'use strict';

    angular.module('app.configModule')
        .filter('nl2br', function() {
            return function(input) {
                if (input !== void 0) {
                    return input.replace(/\n/g, '<br>');
                }
            };
        });
})();
(function () {
'use strict';

angular.module('app.configModule').filter('truncate', [truncateFilter]);
    function truncateFilter() {

        return function(str, num, append){
            num = num || 75;
            append = append || '...';

            if (str.length > num) {
                return _.truncate(str, { length: num, separator: /,? +/, omission: append })
            } else {
                return str;
            }
        };

    }
})();

(function () {
'use strict';

angular.module('app.configModule').factory('configService', ['$window', configService]);
    function configService ($window){
        var service = {
            _vars: $window.config,
            _flatVars: null,

            flattenConfig: function(ob){
                var toReturn = {};

                for (var i in ob) {
                    if (!ob.hasOwnProperty(i)) continue;

                    toReturn[i] = ob[i];

                    if ((typeof ob[i]) == 'object') {
                        var flatObject = this.flattenConfig(ob[i]);
                        for (var x in flatObject) {
                            if (!flatObject.hasOwnProperty(x)) continue;

                            toReturn[i + '.' + x] = flatObject[x];
                        }
                    }
                }
                return toReturn;
            },

            get: function(index, defaultVal){
                defaultVal = (typeof defaultVal !== 'undefined') ? defaultVal : null;
                index = (typeof index !== 'undefined') ? index : null;

                if (index !== null) {
                    return (typeof this._flatVars[index] !== undefined) ? this._flatVars[index] : defaultVal;
                } else {
                    return null;
                }
            },

            onload: function(){
                this._flatVars = this.flattenConfig(this._vars);
            }
        };

        service.onload();

        return service;
    }
})();

(function () {
    'use strict';

    angular.module('app.userModule', ['app.configModule', 'app.apiModule', 'ui.bootstrap'])
        .config(function(){
            // Configuration logic (if any)
        })
        .run(['$window', '$rootScope', 'userService', function($window, $rootScope, userService){
            $window.userService = userService; // Existing logic

            const events = ['mousemove', 'keydown', 'click', 'touchstart'];

            // Function to reset the inactivity timer
            function resetInactivityTimer() {
                userService.startInactivityTimer();
            }

            // Attach event listeners for user activity
            events.forEach(event => {
                document.addEventListener(event, resetInactivityTimer);
            });

            // Start the inactivity timer when the app initializes
            userService.startInactivityTimer();

            // Cleanup: Remove event listeners and cancel timer on app destroy
            $rootScope.$on('$destroy', () => {
                events.forEach(event => {
                    document.removeEventListener(event, resetInactivityTimer);
                });
                userService.cancelInactivityTimer();
            });
        }]);
})();

(function () {
    'use strict';

    angular.module('app.userModule')
        .directive('openForgotPassword', ['userService', 'commonModals', function(userService, commonModals){
            return {
                restrict: 'A',
                replace: false,
                scope: {},
                link: function(scope, element, attrs) {
                    element.on('click', function(event){
                        event.preventDefault();

                        userService.forgotPasswordModal().then(function(){
                            return commonModals.alertModal('A password reset link will be sent to this email if an account is registered under it.', 'Password Sent');
                        });
                    });
                }
            };
        }]);
})();
(function () {
'use strict';

angular.module('app.userModule').factory('userService', ['apiUserService', '$q', '$uibModal', '$rootScope', '$window', '$timeout', '$location', userService]);
function userService(apiUserService, $q, $uibModal, $rootScope, $window, $timeout, $location) {

        var service = {};
        var timeoutPromise;
        const inactivityLimit = 31 * 60 * 1000;

        service.openSignupModal = function(){
            var deferred = $q.defer();
            var _self = this;

            var signupModal = $uibModal.open({
                scope: $rootScope.$new(),
                show: false,
                placement: 'center',
                templateUrl: 'user/directives/signup.modal.html',
                controller: function($scope){

                },
                onHide: function($scope){
                    console.log('signup onHide arguments', arguments);
                }
            });

            signupModal.$promise.then(signupModal.show);

            return deferred.promise;
        };


        service.login = function(params){
            var deferred = $q.defer();

            apiUserService.login(params).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });

            return deferred.promise;
        };

        service.getCurrentUser = function(params){
            var deferred = $q.defer();

            apiUserService.getCurrentUser(params).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });

            return deferred.promise;
        };

        service.register = function(params){
            var deferred = $q.defer();

            apiUserService.register(params).then(function(result){
                deferred.resolve(result.data);
            }, function(err){
                deferred.reject(err);
            });

            return deferred.promise;
        };

        service.forgotPassword = function(params){
            return apiUserService.forgotPassword(params).then(function(result){
                return result.data;
            });
        };
        

        service.forgotPasswordModal = function(){
            var _service = this;

            var passwordModal = $uibModal.open({
                scope: $rootScope.$new(),
                windowClass: 'modal forgot-password',
                templateUrl: 'user/directives/forgot-password.modal.html',
                controllerAs: 'modalCtrl',
                backdrop: true,
                animation: true,
                controller: function($scope){
                    var modalCtrl = this;

                    modalCtrl.form = {
                        email: ''
                    };

                    modalCtrl.message = '';

                    modalCtrl.isLoading = false;

                    modalCtrl.submit = function(){
                        var params = angular.copy(modalCtrl.form);

                        modalCtrl.isLoading = true;
                        _service.forgotPassword(params).then(function(result){
                            modalCtrl.isLoading = false;
                            if (result.success) {
                                console.log('close modal');
                                $scope.$close();
                            } else {
                                modalCtrl.message = "We failed to process your request. Please try again later.";
                            }
                        }, function(err) {
                            modalCtrl.isLoading = false;
                            modalCtrl.message = err.data.email;
                        });
                    };
                }
            });

            return passwordModal.result;
        };

        service.startInactivityTimer = function() {
            service.cancelInactivityTimer();
            timeoutPromise = $timeout(() => {
                service.logoutDueToInactivity();
            }, inactivityLimit);
        };
    
        service.cancelInactivityTimer = function() {
            if (timeoutPromise) {
                $timeout.cancel(timeoutPromise);
            }
        };
    
        service.logoutDueToInactivity = function() {
            if (window.config.user_api_token) {
            alert('You have been logged out due to inactivity.');
            window.location = '/';
            }
        };

        return service;

    }
})();
